import axios from "../../api/axios.private";
import { BackendTraningEntry } from "../../types/training.types";

class TrainingListOrganizationService {
  async list(organizationid: string): Promise<BackendTraningEntry[]> {
    let result: BackendTraningEntry[] = [];

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/training/list";
    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      organization_id: organizationid,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new TrainingListOrganizationService();
export default instance;
