import classes from "./UserHeaderItem.module.css";

export const UserHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username}>Username</div>
      <div className={classes.apiallowmultiplelogin}>API Multiple Login</div>
      <div className={classes.haspassword}>Has Password</div>
      <div className={classes.active}>Active</div>
      <div className={classes.canexpire}>Can expire</div>
      <div className={classes.expireson}>Expires on</div>
      <div className={classes.edit}>Edit</div>
    </div>
  );
};
