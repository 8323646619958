import classes from "./ContainerApplicationHeaderItem.module.css";

export const ContainerApplicationHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>File</div>
      <div className={classes.action}>Action</div>
      <div className={classes.action}>Action</div>
    </div>
  );
};
