import axios from "../../api/axios.private";

class AdminAddService {
  async add(
    username: string,
    password: string,
    active: string,
    can_expire: string,
    expires_on: string,
    organization_id: string,
    canAccessVideo: string,
    canAccessApplication: string
  ): Promise<number> {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/admin/add";

    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      username: username,
      password: password,
      active: active,
      can_expire: can_expire,
      expires_on: expires_on,
      organization_id: organization_id,
      can_access_video: canAccessVideo,
      can_access_application: canAccessApplication,
    };

    //console.log(data);

    let statusCode = 0;

    await axios
      .post(url, data, config)
      .then((res) => {
        statusCode = res.status;
      })
      .catch((err) => {
        //console.log(err);
        statusCode = err.status;
      });
    //.catch((err) => {});

    return statusCode;
  }
}

const instance = new AdminAddService();
export default instance;
