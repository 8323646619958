import { ContainerVideoItemProps } from "../../../types/filemanager.types";
import classes from "./ContainerApplicationItem.module.css";

export const ContainerApplicationItem = ({ onClickActionDownload, onClickActionDelete, item }: ContainerVideoItemProps) => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>{item.name}</div>
      <div
        className={classes.action}
        onClick={() => {
          onClickActionDelete(item);
        }}
      >
        Delete
      </div>
      <div
        className={classes.action}
        onClick={() => {
          onClickActionDownload(item);
        }}
      >
        Download
      </div>
    </div>
  );
};
