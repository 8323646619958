import azuresasAccountService from "../../../services/azuresas/azuresas.account.service";
import azuresasGenerateService from "../../../services/azuresas/azuresas.generate.service";
import uploadAzureFileService from "../../../services/uploadazurefile/upload.azure.file.service";
import InputFile from "../../form/inputfile/InputFile";
import { UploadProps } from "../../../types/upload.types";
import { useEffect, useRef, useState } from "react";
import { AZURESAS_TOKEN_EXPIRATION } from "../../../types/common.types";
//import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";

export const UploadApplication = ({ onUploadDone, onUploadStarted, onUploadProgress, identifier, container }: UploadProps) => {
  const [containerName, setContainerName] = useState("");
  const [folderName, setFolderName] = useState("tmp");
  const [progressText, setProgressText] = useState("");
  const folderNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setContainerName(container);
  }, [container, containerName]);

  function onCompleted(azureUrl: string) {
    console.log(azureUrl);
    console.log(`Upload azureUrl=${azureUrl} successfully`, azureUrl);
  }
  function onUploadError() {
    console.log("UPLOAD ERROR");
  }

  async function upload(fileList: FileList, folderpath: string) {
    if (folderpath.length === 0) {
      setProgressText("Can not upload file. You need to set a folder.");
      console.log("not uploading");
      return;
    }

    //const accountData = await azuresasAccountService.get(identifier);
    //const sasCredentials = await azuresasGenerateService.generate(identifier, containerName, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    // const result = await uploadAzureFileService.execute(onUploadProgress2, onCompleted2, onUploadStarted2, onError2, identifier, containerName, fileList[0], "tmp/" + fileList[0].name);
    // console.log("result=" + result);
    //const blobService = new BlobServiceClient(`https://${accountData.storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    //const containerClient = blobService.getContainerClient(containerName);

    //const file = fileList[0];
    //const result = await uploadAzureFileService.execute(uploadProgress, onCompleted, onUploadStarted, onUploadError, identifier, containerName, file, folderpath);
    //console.log("result=" + result);

    Array.from(fileList).forEach(async (file) => {
      let absFile = "";
      if (folderpath.length === 0) {
        absFile = file.name; // put it in the root
      } else {
        //absFile = folderpath + "/" + file.name;
        absFile = folderpath;
      }

      console.log("absFile=" + absFile + " file=" + file.name);
      const result = await uploadAzureFileService.execute(uploadProgress, onCompleted, onUploadStarted, onUploadError, identifier, containerName, file, absFile);
      console.log("result=" + result);

      /*
      const blockBlobClient = await containerClient.getBlockBlobClient(absFile);
      const options: BlockBlobParallelUploadOptions = {
        onProgress: (progress) => {
          uploadProgress(file, progress.loadedBytes);
        },
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      };
      onUploadStarted();
      const uploadBlobResponse = await blockBlobClient.upload(file, file.size, options);
      console.log(`Upload block blob ${file.name} successfully`, uploadBlobResponse.requestId);
      */
    });
  }

  function uploadProgress(file: File, loadedBytes: number) {
    let percentage = Math.round((loadedBytes / file.size) * 100);
    if (percentage === 100) {
      setProgressText("Upload of file " + file.name + " is now complete!");
      onUploadDone();
    } else {
      setProgressText("Upload progress for file " + file.name + " is " + percentage + " % complete");
      onUploadProgress(percentage);
    }
  }

  return (
    <div>
      <div>
        <label htmlFor="organizationidentifier">Enter folder name to place video in</label>
        <input
          data-lpignore="true"
          size={10}
          type="text"
          required
          id="organizationidentifier"
          autoComplete="off"
          ref={folderNameRef}
          value={folderName}
          onChange={(e) => {
            setFolderName(e.target.value);
          }}
        />{" "}
      </div>
      <br />
      <InputFile
        onFilesSelected={(files: FileList) => {
          //console.log(files);
          upload(files, folderName);
        }}
        acceptFileExtensionList={["ipa", "apk", "zip"]}
        text={"Select"}
        disabled={false}
      />
      <div>{progressText}</div>
    </div>
  );
};
