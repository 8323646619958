import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserCredentials } from "../../../types/login.types";
import { UserContext } from "../../../context/UserContext";
import LoginForm from "../../form/login/LoginForm";
import loginService from "../../../services/autentication/login.service";
import classes from "./LoginPage.module.css";
import secureLocalStorage from "react-secure-storage";
import version from "../../../version.json";
import Modal from "../../ui/modal/Modal";
import useModal from "../../form/hooks/useModal";
import { Button } from "../../ui/button/Button";
import twofaVerifyService from "../../../services/autentication/twofa.verify.service";

export const LoginPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  // 2fa
  const { isOpen, toggle } = useModal();
  const twofsInputRef = useRef<HTMLInputElement>(null);
  const [username, setUsername] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");

  async function doLogin(credentials: UserCredentials) {
    const loginResult = await loginService.login(credentials);
    await secureLocalStorage.clear();
    await secureLocalStorage.setItem("access_token", loginResult.access_token);

    if (userContext) {
      const appState = {
        name: credentials.username,
        id: loginResult.userid,
        type: loginResult.type,
        organizationid: loginResult.organizationid,
        organizationname: loginResult.organizationname,
        can_access_application: loginResult.can_access_application,
        can_access_video: loginResult.can_access_video,
      };

      const json = JSON.stringify(appState);
      await secureLocalStorage.setItem("state", json);
      userContext.setUser(appState);

      setUsername(credentials.username);
      if (loginResult.two_factor_autentication_status === 1) {
        setVerificationMessage("");
        toggle();
      } else {
        navigate("/home"); // note that is the user has bad credentials then the home page will find out and send the user back to login
      }
    }
  }

  async function verify2fa() {
    const twofa = twofsInputRef.current ? twofsInputRef.current.value : "";
    if (twofa) {
      const verified = await twofaVerifyService.verify(username, twofa);
      if (verified === 1) {
        setVerificationMessage("SUCCESS - you have access now");
        navigate("/home");
      } else {
        setVerificationMessage("FAILED... try again or give up!");
      }
    } else {
      setVerificationMessage("Enter a code please ...");
    }
  }

  return (
    <section className={classes.section}>
      <div className={classes.logo}></div>
      <LoginForm doLogin={doLogin} />
      <br />
      <div className={classes.version}>Version: {version.version}</div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <h1>2FA autentication</h1>
        <h4>2FA (Two factor autentication) is required to login</h4>
        <br />
        Open your autenticator app now and enter the code below
        <br />
        <br />
        <div className={classes.control}>
          <label htmlFor="2fa">Code: </label>
          <input type="text" required id="2fa" autoComplete="off" ref={twofsInputRef} placeholder="Insert your token" pattern="[0-9]{6}" />
        </div>
        <br />
        <Button text="Verify" onClick={verify2fa} />
        <br />
        <p>{verificationMessage}</p>
      </Modal>
    </section>
  );
};

export default LoginPage;
