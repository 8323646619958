import { useContext, useEffect, useState } from "react";
import classes from "./OrganizationPrincipalList.module.css";
import principalUsersListService from "../../../../services/principal/principal.users.list.service";
import trainingListUserService from "../../../../services/training/training.list.user.service";
import trainingFileService from "../../../../services/training/training.file.service";
import { UserContext } from "../../../../context/UserContext";
import { BackendUser } from "../../../../types/users.types";
import { OrganizationPrincipalItem } from "./OrganizationPrincipalItem";
import { useNavigate } from "react-router-dom";
import { ConverTo } from "../../../../types/training.types";

export const OrganizationPrincipalList = () => {
  const userContext = useContext(UserContext);
  const [users, setUsers] = useState<BackendUser[]>();
  const navigate = useNavigate();

  function editUser(item: BackendUser) {
    navigate("/user/edit", { state: item });
  }

  function downloadLog(item: BackendUser) {
    trainingListUserService.list(item.id.toString()).then((res) => {
      //console.log(res);
      trainingFileService.load(res, item.username);
      trainingFileService.sendAs(ConverTo.CSV); // ConverTo.JSON
    });
  }

  useEffect(() => {
    if (userContext && userContext.user) {
      principalUsersListService.users(userContext.user.organizationid).then((res) => {
        setUsers(res);
        //console.log(res);
      });
    }
  }, []);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "admin") {
      return <div>403</div>;
    }
  }

  const list = users?.map(function (item: BackendUser, id: number) {
    return (
      <OrganizationPrincipalItem
        key={item.id}
        item={item}
        onClickEdit={(item: BackendUser) => {
          editUser(item);
        }}
        onClickDownloadTraininglog={(item: BackendUser) => {
          downloadLog(item);
        }}
      />
    );
  });

  return <div>{list}</div>;
};
