import axios from "../../api/axios.private";
import { BackendOrganizationItem } from "../../types/organization.types";

class PrincipalOrganizationGetService {
  async get(organizationid: string): Promise<BackendOrganizationItem> {
    let result: BackendOrganizationItem = {
      active: 0,
      appid: "",
      container_app: "",
      container_video: "",
      id: "",
      identifier: "",
      name: "",
      organization_id: "",
      storage_name: "",
      access_key: "",
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/principal/organization/get";

    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      organizationid: organizationid,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new PrincipalOrganizationGetService();
export default instance;
