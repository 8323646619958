import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { AdminList } from "../../../ui/admin/list/AdminList";

export const AdminListPage = () => {
  const userContext = useContext(UserContext);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  return <AdminList />;
};
