import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import classes from "./AdminEditResetAdminPassword.module.css";
import sharedUtilPassword from "../../../../services/shared/shared.util.password";
import adminsResetService from "../../../../services/admins/admins.reset.service";
import { Button } from "../../button/Button";
import { BackendUser } from "../../../../types/users.types";

export const AdminEditResetAdminPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [user, setUser] = useState<BackendUser>();

  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPassword(sharedUtilPassword.create(4));
    //console.log(location.state);
    setUser(location.state);
  }, []);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  async function save(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user) {
      const httpCode = await adminsResetService.reset(user.id, user.organization_id, user.username, password);
      if (httpCode === 200) {
        navigate("/home");
      } else {
        setMessage("Unable to reset password.");
      }
    }
  }

  return (
    <div className={classes.container}>
      <div>
        <h1>Set new temporarly password for user {user?.username}</h1>
        <p>Rememeber that the admin will be prompted for at new password on next login.</p>
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="password">Password</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="password"
          autoComplete="off"
          ref={passwordInputRef}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <br />
      <Button text="Set this as a new temporarly password" onClick={save} />
      <span className={classes.message}>{message}</span>
    </div>
  );
};
