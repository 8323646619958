import axios from "../../api/axios.private";
import { TwoFASetupServiceItem } from "../../types/twofa.types";

class TwoFASetupService {
  async setup(id: number): Promise<TwoFASetupServiceItem> {
    let result: TwoFASetupServiceItem = { two_fa_secret: "" };

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/autentication/2fa/setup";

    const data = {
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        //console.log(res.data);
        result = res.data;
      })
      //.catch((err) => console.log(err));
      .catch((err) => {
        return 0;
      });

    return result;
  }
}

const instance = new TwoFASetupService();
export default instance;
