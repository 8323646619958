import axios from "../../api/axios.private";
import { BackendAuditlogItem } from "../../types/auditlog.types";

class AuditlogListService {
  async list(organizationid: string): Promise<BackendAuditlogItem[]> {
    let result: BackendAuditlogItem[] = [];

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/auditlog/list";
    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      organizationid: organizationid,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new AuditlogListService();
export default instance;
