import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { Button } from "../../../../ui/button/Button";
import { ContainerApplicationPrincipalList } from "../../../../ui/containerapplication/principal/ContainerApplicationPrincipalList";
import classes from "./AzureStoragePrincipalDownloadPage.module.css";

export const AzureStoragePrincipalDownloadPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "admin") {
      return <div>403</div>;
    }
  }

  return (
    <div className={classes.container}>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <h1>Application download</h1>
      <br />
      <ContainerApplicationPrincipalList identifier={location.state.identifier} container={location.state.container_app} />
    </div>
  );
};
