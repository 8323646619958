import { BackendOrganizationItem, OrganizationListPageProps } from "../../../../types/organization.types";
import { Button } from "../../../ui/button/Button";
import { OrganizationList } from "../../../ui/organization/OrganizationList";
import classes from "./OrganizationListPage.module.css";

export const OrganizationListPage = ({ onOrganizationListClicked, onAddOrganizationClicked }: OrganizationListPageProps) => {
  function onOrganizationListClickedHandle(place: string, item: BackendOrganizationItem) {
    onOrganizationListClicked(place, item);
  }
  function addOrganization(event: { preventDefault: () => void }): void {
    event.preventDefault();
    onAddOrganizationClicked();
  }

  return (
    <div>
      <br />
      <Button text="Add organization" onClick={addOrganization} />
      <OrganizationList onOrganizationListClicked={onOrganizationListClickedHandle} />
    </div>
  );
};
