import { BlobServiceClient } from "@azure/storage-blob";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";
import azuresasGenerateService from "./azuresas.generate.service";

class AzureSASVideoManifestSerivce {
  filename = "manifest.txt";

  async blobToString(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      fileReader.onloadend = (ev: any) => {
        resolve(ev.target!.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }
  async get(identifier: string, storageaccount: string, containername: string): Promise<string[]> {
    let result: string[] = [];

    //console.log(".......storageaccount=" + storageaccount);
    if (storageaccount === "") return result;

    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);

    try {
      const blobClient = containerClient.getBlobClient(this.filename);
      const downloadBlockBlobResponse = await blobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;
      if (blobBody) {
        const manifest = await this.blobToString(blobBody);
        const manifestLines = manifest.split("\n");
        manifestLines.map((line) => {
          if (line.length !== 0) {
            result.push(line);
          }
          //console.log(line);
          return line;
        });
      }
    } catch (error) {
      //console.log("manifest error");
      await this.set(identifier, storageaccount, containername, []); // create a empty manifest file
      await this.get(identifier, storageaccount, containername); // read the content which will be empty
    }

    //console.log(result);
    return result;
  }
  async set(identifier: string, storageaccount: string, containername: string, entries: string[]) {
    let content = "";
    entries.map((line) => {
      content += line + "\n";
      return line;
    });
    //console.log(content);
    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);
    const blockBlobClient = await containerClient.getBlockBlobClient(this.filename);
    await blockBlobClient.upload(content, content.length);
  }
  getUrl(storageaccount: string) {
    return `https://${storageaccount}.blob.core.windows.net/`;
  }
}

const instance = new AzureSASVideoManifestSerivce();
export default instance;
