import axios from "../../api/axios.private";

class PrincipalSetPasswordService {
  async set(id: number, username: string, password: string): Promise<number> {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/principal/users/setpassword";
    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      id: id,
      username: username,
      password: password,
    };

    let statusCode = 0;
    await axios
      .post(url, data, config)
      .then((res) => {
        statusCode = res.status;
      })
      .catch((err) => {
        console.log(err);
        statusCode = err.status;
      });
    //.catch((err) => {});

    return statusCode;
  }
}

const instance = new PrincipalSetPasswordService();
export default instance;
