import classes from "./Button.module.css";
interface ButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
export const Button = ({ text, onClick }: ButtonProps) => {
  return (
    <button className={classes.btn} onClick={onClick}>
      {text}
    </button>
  );
};
