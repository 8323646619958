import axios from "../../api/axios.private";

class PrincipalUsersEditService {
  async edit(id: number, username: string, active: string, can_expire: string, expires_on: string, organizationid: string): Promise<number> {
    let result: number = 0;

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/principal/users/edit";

    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      id: id,
      username: username,
      active: active,
      can_expire: can_expire,
      expires_on: expires_on,
      organization_id: organizationid,
    };

    //console.log(data);

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => {
        console.log(err);
      });
    //.catch((err) => {});

    return result;
  }
}

const instance = new PrincipalUsersEditService();
export default instance;
