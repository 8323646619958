import { useRef, useState } from "react";
import { SquareTextBoxProps } from "../../../types/squaretextbox.types";
import classes from "./SquareTextBox.module.css";

export const SquareTextBox = ({ onClick, id, text, size, defaultvalue }: SquareTextBoxProps) => {
  const folderNameRef = useRef<HTMLInputElement>(null);
  const [folderName, setFolderName] = useState(defaultvalue);

  function localOnClick() {
    onClick(id, text, folderNameRef.current?.value ?? "none");
  }
  return (
    <div key={id}>
      <div className={classes.box} onClick={localOnClick}>
        <div className={classes.row}>
          <div className={classes.column}>
            <input
              data-lpignore="true"
              size={size}
              type="text"
              required
              id={id}
              autoComplete="off"
              ref={folderNameRef}
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
          </div>
          <div className={classes.column}>{text}</div>
        </div>
      </div>
    </div>
  );
};
