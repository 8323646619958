import React from "react";
import { useRef } from "react";
import { UserCredentials } from "../../../types/login.types";
import { Button } from "../../ui/button/Button";
import Card from "../../ui/card/Card";
import classes from "./LoginForm.module.css";

interface Props {
  doLogin(credentials: UserCredentials): void;
}

export const LoginForm = ({ doLogin }: Props) => {
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  function submitHandler(event: { preventDefault: () => void }) {
    event.preventDefault();

    // if (usernameInputRef.current && passwordInputRef.current) {
    //   usernameInputRef.current.value = "su";
    //   passwordInputRef.current.value = "En23456";
    // }

    const eneretedUsername = usernameInputRef.current ? usernameInputRef.current.value : "";
    const eneretedPassword = passwordInputRef.current ? passwordInputRef.current.value : "";
    let userCredentials = { username: eneretedUsername, password: eneretedPassword };
    doLogin(userCredentials);
  }

  return (
    <Card size="small">
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="username">Username</label>
          <input type="text" required id="username" ref={usernameInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Password</label>
          <input type="password" required id="password" ref={passwordInputRef} />
        </div>
        <div className={classes.actions}>
          <label htmlFor="login"></label>
          <Button text="Login" onClick={submitHandler} />
        </div>
      </form>
    </Card>
  );
};

export default LoginForm;
