import { UserItemProps } from "../../../../types/users.types";
import classes from "./UserItem.module.css";

export const UserItem = ({ onClick, item }: UserItemProps) => {
  function resolveActive() {
    return item.active === 1 ? "Yes" : "No";
  }
  function resolveHasPassword() {
    // not the has_temp_password
    return item.hash.length === 0 ? "No" : "Yes";
  }
  function resolveCanExpire() {
    return item.can_expire === 0 ? "No" : "Yes";
  }
  function apiAllowMultipleLogin() {
    return item.api_allow_multiple_login === 0 ? "No" : "Yes";
  }
  function resolveExpire() {}
  function onItemClick() {
    onClick(item);
  }
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username} key={item.id}>
        <div>{item.username}</div>
      </div>
      <div className={classes.apiallowmultiplelogin}>{apiAllowMultipleLogin()}</div>
      <div className={classes.haspassword}>{resolveHasPassword()}</div>
      <div className={classes.active}>{resolveActive()}</div>
      <div className={classes.canexpire}>{resolveCanExpire()}</div>
      <div className={classes.expireson}>{item.expires_on}</div>
      <div className={classes.edit} onClick={onItemClick}>
        Edit
      </div>
    </div>
  );
};
