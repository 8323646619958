import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:5555" : "/";

const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const accessToken = await secureLocalStorage.getItem("access_token");
    if (accessToken && config.headers) {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error.response || error.message);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const accessToken = await secureLocalStorage.getItem("access_token");
      if (accessToken) {
        await secureLocalStorage.setItem("access_token", accessToken);
        prevRequest.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return axiosPrivate(prevRequest);
    }
    return Promise.reject(error.response || error.message);
  }
);

export default axiosPrivate;
