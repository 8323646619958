import React, { useRef } from "react";
import { InputFileProps } from "../../../types/common.types";
import { Button } from "../../ui/button/Button";

export const InputFile = ({ onFilesSelected, acceptFileExtensionList, text }: InputFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  function files(files: FileList | null) {
    if (files) {
      const split = files[0].name.split(".");
      const ext = split[split.length - 1];
      if (acceptFileExtensionList.includes(ext)) {
        onFilesSelected(files);
      }
    }
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        type="file"
        multiple={false}
        onChange={(e) => {
          files(e.target.files);
        }}
      />
      <div>
        <Button
          text={text}
          onClick={() => {
            showFileDialog();
          }}
        />
        Accepting {acceptFileExtensionList.join(", ")} files.
      </div>
    </div>
  );
};

export default InputFile;
