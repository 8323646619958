import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminsListService from "../../../../services/admins/admins.list.service";
import { BackendOrganizationItem } from "../../../../types/organization.types";
import { BackendUser } from "../../../../types/users.types";
import { Button } from "../../button/Button";
import { AdminHeaderItem } from "./AdminHeaderItem";
import { AdminItem } from "./AdminItem";
import classes from "./AdminList.module.css";

export const AdminList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [locationState, setLocationState] = useState<BackendOrganizationItem>();
  const [users, setUsers] = useState<BackendUser[]>();

  useEffect(() => {
    setLocationState(location.state);
    adminsListService.list(location.state.organization_id).then((res) => {
      setUsers(res);
      //console.log(res);
    });
  }, [location.state]);

  function onClickEdit(item: BackendUser) {
    navigate("/admin/edit", { state: item });
  }

  function onClickAdd() {
    navigate("/admin/add", { state: locationState });
  }

  const list = users?.map(function (item: BackendUser, id: number) {
    return <AdminItem key={item.id} item={item} onClick={onClickEdit} />;
  });

  return (
    <div className={classes.container}>
      <h1>Admins for [{locationState?.name}]</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <Button text="Add an admin" onClick={onClickAdd} />
      <br />
      <br />
      <AdminHeaderItem />
      {list}
    </div>
  );
};
