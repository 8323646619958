import { BoxActionProps } from "../../../types/boxaction.types";
import classes from "./BoxAction.module.css";

export const BoxAction = ({ onClick, disabled, id, text }: BoxActionProps) => {
  function localOnClick() {
    onClick(id, text);
  }
  return (
    <div key={id}>
      {disabled && (
        <div className={classes.disabled} onClick={localOnClick}>
          {text}
        </div>
      )}
      {!disabled && (
        <div className={classes.box} onClick={localOnClick}>
          {text}
        </div>
      )}
    </div>
  );
};
