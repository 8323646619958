import axios from "../../api/axios.private";

class AdminEditService {
  async edit(id: number, username: string, active: string, can_expire: string, expires_on: string, organizationid: string, can_access_video: string, can_access_application: string): Promise<number> {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/admin/edit";

    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      id: id,
      username: username,
      active: active,
      can_expire: can_expire,
      expires_on: expires_on,
      organization_id: organizationid,
      can_access_video: can_access_video,
      can_access_application: can_access_application,
    };

    //console.log(data);

    let statusCode = 0;
    await axios
      .post(url, data, config)
      .then((res) => {
        statusCode = res.status;
      })
      .catch((err) => {
        //console.log(err);
        statusCode = err.status;
      });
    //.catch((err) => {});

    return statusCode;
  }
}

const instance = new AdminEditService();
export default instance;
