import axios from "../../api/axios.private";
import { BackendOrganizationStatisticsStats } from "../../types/organization.statistics.stats.types";

class OrganizationStatisticsStatsAllService {
  async all(): Promise<BackendOrganizationStatisticsStats[]> {
    let result: BackendOrganizationStatisticsStats[] = [];

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/organization/stats";
    const data = {
      dummy: "hello",
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

export default new OrganizationStatisticsStatsAllService();
