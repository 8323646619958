import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackendUser } from "../../../../types/users.types";
import { Button } from "../../button/Button";
import { CheckBox } from "../../checkbox/CheckBox";
import classes from "./UserEditPage.module.css";
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
//import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import usersEditService from "../../../../services/users/users.edit.service";
import usersDeleteService from "../../../../services/users/users.delete.service";
import usersResetService from "../../../../services/users/users.reset.service";
import { UserContext } from "../../../../context/UserContext";

export const UserEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);

  const [user, setUser] = useState<BackendUser>();
  const [username, setUsername] = useState("");
  const [active, setActive] = useState(false);
  const [canExpire, setCanExpire] = useState(false);
  const [apiallowmultiplelogin, setApiAllowMultipleLogin] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  //const [selectedDay, setSelectedDay] = useState<DayValue>();
  const [selectedDay, setSelectedDay] = useState<string>("");

  useEffect(() => {
    setUser(location.state);
    setUsername(location.state.username);
    setActive(location.state.active === 1 ? true : false);
    setSelectedDay(location.state.expires_on);
    // setSelectedDay({
    //   day: new Date(location.state.expires_on).getDate(),
    //   month: new Date(location.state.expires_on).getMonth() + 1,
    //   year: new Date(location.state.expires_on).getFullYear(),
    // });
    setCanExpire(location.state.can_expire === 1 ? true : false);
    setApiAllowMultipleLogin(location.state.api_allow_multiple_login === 1 ? true : false);
  }, [location.state]);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const selectedDayInputRef = useRef<HTMLInputElement>(null);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  async function save(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user && selectedDay) {
      //console.log(selectedDay);
      //const expiresOn = new Date(selectedDay.year ?? 0, selectedDay.month - 1 ?? 0, selectedDay.day + 1 ?? 0).toISOString().split("T")[0];
      //console.log(expiresOn);

      if (selectedDay.split("-").length !== 3) {
        return;
      }

      const yyyy = parseInt(selectedDay.split("-")[0]);
      const mm = parseInt(selectedDay.split("-")[1]);
      const dd = parseInt(selectedDay.split("-")[2]);
      const expiresOn = new Date(yyyy ?? 0, mm - 1 ?? 0, dd + 1 ?? 0).toISOString().split("T")[0];

      const statusCode = await usersEditService.edit(user.id, username, active ? "1" : "0", canExpire ? "1" : "0", expiresOn, user.organization_id, apiallowmultiplelogin ? "1" : "0");

      if (statusCode === 200) {
        navigate(-1);
      } else {
        setError("user already exsist");
      }
    }
  }
  function reset(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user) {
      usersResetService.reset(user.id, user.organization_id, user.username);
      setMessage("The google user was reset! User must re-login to set a new passwrod.");
    }
  }
  function remove(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user) {
      usersDeleteService.delete(user.id, user.organization_id);
    }
    navigate(-1);
  }
  function activeChanged(active: boolean) {
    setActive(active);
  }
  function canExpireChanged(active: boolean) {
    setCanExpire(active);
  }

  return (
    <div className={classes.container}>
      <h1>Edit user [{user?.username}]</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <div className={classes.control}>
        <label htmlFor="username">Username</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="username"
          autoComplete="off"
          ref={usernameInputRef}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </div>
      <span className={classes.error}>{error}</span>
      <span className={classes.message}>{message}</span>
      <br />
      <CheckBox initialState={active} label="Active" onStateChange={activeChanged} />
      <CheckBox initialState={canExpire} label="Can expire" onStateChange={canExpireChanged} />
      <CheckBox initialState={apiallowmultiplelogin} label="Allow multiple logins from this user (will not work when you data collect)" onStateChange={setApiAllowMultipleLogin} />
      <br />
      <div>
        {/* <p>Expires on</p> */}
        {/* <DatePicker value={selectedDay} onChange={setSelectedDay} inputPlaceholder="Select a day" shouldHighlightWeekends /> */}
        <div className={classes.control}>
          <label htmlFor="selectedDay">Expire On (YYYY-MM-DD eg 2025-12-31)</label>
          <input
            data-lpignore="true"
            type="text"
            required
            id="selectedDay"
            autoComplete="off"
            ref={selectedDayInputRef}
            value={selectedDay}
            onChange={(e) => {
              setSelectedDay(e.target.value);
            }}
          />
        </div>
      </div>
      <br />
      <Button text="Save" onClick={save} />
      <Button text="Reset password" onClick={reset} />
      <Button text="Delete" onClick={remove} />
    </div>
  );
};
