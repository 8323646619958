import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BackendOrganizationItem } from "../../../types/organization.types";
import { OrganizationListPage } from "../organization/list/OrganizationListPage";
import trainingListService from "../../../services/training/training.list.organization.service";
import trainingFileService from "../../../services/training/training.file.service";
import { ConverTo } from "../../../types/training.types";
import { UserContext } from "../../../context/UserContext";

export const HomePage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  function onOrganizationListClickedHandle(place: string, item: BackendOrganizationItem) {
    if (place === "organization-edit") {
      navigate("/organization/edit", { state: item });
    }
    if (place === "users") {
      navigate("/users/list", { state: item });
    }
    if (place === "admins") {
      navigate("/admin/list", { state: item });
    }
    if (place === "auditlogs") {
      navigate("/auditlog/list", { state: item });
    }
    if (place === "azure-storage-video") {
      navigate("/azure/storage/video", { state: item });
    }
    if (place === "azure-storage-application") {
      navigate("/azure/storage/application", { state: item });
    }
    if (place === "training") {
      trainingListService.list(item.organization_id).then((res) => {
        //console.log(res);
        trainingFileService.load(res, item.name);
        //trainingFileService.sendAs(ConverTo.JSON);
        trainingFileService.sendAs(ConverTo.CSV);
      });
    }
  }
  function addOrganization() {
    navigate("/organization/add");
  }

  return <OrganizationListPage onAddOrganizationClicked={addOrganization} onOrganizationListClicked={onOrganizationListClickedHandle} />;
};

export default HomePage;
