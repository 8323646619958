import axios from "../../api/axios.private";
import { BackendAzureSASItem } from "../../types/azuresas.types";
import sharedUtilDelay from "../shared/shared.util.delay";

class AzureSASService {
  async generate(identifier: string, containername: string, expirationtime: string): Promise<BackendAzureSASItem> {
    let result: BackendAzureSASItem = { identifier: "", containername: "", expirationtime: "", sas: "", storageaccount: "" };

    await sharedUtilDelay.delay(500); // the server thinks it's a Dos attack?!

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/azuresas/generate";
    const data = {
      containername: containername,
      expirationtime: expirationtime,
      identifier: identifier,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new AzureSASService();
export default instance;
