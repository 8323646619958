import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui/button/Button";
import classes from "./OrganizationAddPage.module.css";
import organizationAddService from "../../../../services/organization/organization.add.service";
import { UserContext } from "../../../../context/UserContext";

export const OrganizationAddPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [name, setName] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [storagename, setStorageName] = useState("");
  const [accesskey, setAccessKey] = useState("");

  const nameInputRef = useRef<HTMLInputElement>(null);
  const identifierInputRef = useRef<HTMLInputElement>(null);
  const storageNameInputRef = useRef<HTMLInputElement>(null);
  const accessKeyInputRef = useRef<HTMLInputElement>(null);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  async function add(event: { preventDefault: () => void }) {
    event.preventDefault();
    await organizationAddService.create(name, identifier, storagename, accesskey);
    navigate(-1);
  }

  return (
    <div className={classes.container}>
      <h1>Add Organization</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <div className={classes.control}>
        <label htmlFor="organizationname">Organization name</label>
        <input
          type="text"
          required
          id="organizationname"
          autoComplete="off"
          ref={nameInputRef}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="organizationidentifier">Organization identifier</label>
        <input
          type="text"
          required
          id="organizationidentifier"
          autoComplete="off"
          ref={identifierInputRef}
          value={identifier}
          onChange={(e) => {
            setIdentifier(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="storagename">Storage account name</label>
        <input
          type="text"
          required
          id="storagename"
          autoComplete="off"
          ref={storageNameInputRef}
          value={storagename}
          onChange={(e) => {
            setStorageName(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="accesskey">Access key</label>
        <input
          type="text"
          required
          id="accesskey"
          autoComplete="off"
          ref={accessKeyInputRef}
          value={accesskey}
          onChange={(e) => {
            setAccessKey(e.target.value);
          }}
        />
      </div>
      <br />
      <Button text="Add" onClick={add} />
    </div>
  );
};
