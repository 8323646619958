import { AuditlogItemProps } from "../../../types/auditlog.types";
import classes from "./AuditlogItem.module.css";

export const AuditlogItem = ({ item }: AuditlogItemProps) => {
  return (
    <div className={classes.gridcontainer} key={item.id}>
      <div className={classes.syncron}>{item.syncron}</div>
      <div className={classes.username}>{item.username}</div>
      <div className={classes.actionperformed}>{item.action_performed}</div>
      <div className={classes.extra}>{item.extra_data}</div>
    </div>
  );
};
