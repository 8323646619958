export interface SharedUtilExtractFileStructureInfo {
  filename: string;
  type: string;
  onlyfolders: string[];
  path: string;
}

class SharedUtilExtractFileStructure {
  extract(input: string, storagestring: string): SharedUtilExtractFileStructureInfo {
    let info: SharedUtilExtractFileStructureInfo = {
      filename: "",
      type: "",
      onlyfolders: [],
      path: "",
    };

    const split = input.split("/");
    const filename: string = split[split.length - 1];
    const typesplit: string[] = split[0].split(";");
    const folderssplit1: string[] = input.split(storagestring);
    const folderssplit1onlyfolders = folderssplit1[1];
    const folders = folderssplit1onlyfolders.split("/").slice(2); // remove the first : and second 'video' or next part
    const folderstructure = folders.join(" / ");

    info.filename = filename;
    info.type = typesplit[0];
    info.onlyfolders = folders;
    info.path = folderstructure;

    return info;
  }
}

const instance = new SharedUtilExtractFileStructure();
export default instance;
