import axios from "../../api/axios.private";
import { LoginResult } from "../../types/login.types";
import { UserCredentials } from "../../types/login.types";

class LoginService {
  async login(credentials: UserCredentials): Promise<LoginResult> {
    let result: LoginResult = {
      username: "",
      access_token: "",
      userid: 0,
      type: "",
      organizationid: "",
      organizationname: "",
      can_access_application: 0,
      can_access_video: 0,
      two_factor_autentication_status: 0,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/autentication/login";
    const data = {
      username: credentials.username,
      password: credentials.password,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        try {
          const valid1 = "access_token" in res.data;
          const valid2 = "userid" in res.data;
          const valid3 = "type" in res.data;
          const valid4 = "organizationid" in res.data;
          const valid5 = "organizationname" in res.data;
          const valid6 = "can_access_video" in res.data;
          const valid7 = "can_access_application" in res.data;
          if (valid1 && valid2 && valid3 && valid4 && valid5 && valid6 && valid7) {
            result = res.data;
          }
        } catch (error) {}
      })
      //.catch((err) => console.log(err));
      .catch((err) => {
        console.log(err);
        return result;
      });

    return result;
  }
}

const instance = new LoginService();
export default instance;
