import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { AppStateContext } from "../../context/AppStateContext";
import classes from "./ContentWrapper.module.css";

export const ContentWrapper: React.FunctionComponent<PropsWithChildren> = (props) => {
  const appState = useContext(AppStateContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (appState && appState.state) {
      setShow(appState.state.spinner);
    }
  }, [appState]);

  return (
    <div className={classes.container}>
      {show && (
        <div className={classes.spinner}>
          <BeatLoader color="#FF0000" size={25} />
        </div>
      )}
      <div>{props.children}</div>
    </div>
  );
};

export default ContentWrapper;
