import axios from "../../api/axios.private";
import { BackendUser } from "../../types/users.types";

class PrincipalUsersService {
  async users(organizationid: string): Promise<BackendUser[]> {
    let result: BackendUser[] = [];

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/principal/users/list";
    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      organizationid: organizationid,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    //.catch((err) => {});

    return result;
  }
}

const instance = new PrincipalUsersService();
export default instance;
