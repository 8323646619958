import { useContext, useEffect, useState } from "react";
import classes from "./UploadVideo.module.css";
import { SquareBox } from "../squarebox/SquareBox";
import azuresasAccountService from "../../../services/azuresas/azuresas.account.service";
import azuresasListblobService from "../../../services/azuresas/azuresas.listblob.service";
import { AzureFileBlobItem } from "../../../types/azuresas.types";
import { SquareTextBox } from "../squaretextbox/SquareTextBox";
import { FolderBox } from "../folderbox/FolderBox";
import { FileBox } from "../filebox/FileBox";
import { ScreenFile, ScreenFolder } from "../../../types/screendata.types";
import uploadAzureFileService from "../../../services/uploadazurefile/upload.azure.file.service";
import deleteAzureFileService from "../../../services/deleteazurefile/delete.azure.file.service";
import { ThumbnailControl } from "./ThumbnailControl";
import { UploadVideoFileToAzureControl } from "./UploadVideoFileToAzureControl";
import azuresasManifestService from "../../../services/azuresas/azuresas.manifest.service";
import { AppStateContext } from "../../../context/AppStateContext";
import { UploadProps } from "../../../types/upload.types";
import renameAzureFileService from "../../../services/renameazurefile/rename.azure.file.service";

export const UploadVideo = ({ reloadParent, onUploadDone, onUploadStarted, onUploadProgress, identifier, container }: UploadProps) => {
  //console.log(identifier + " " + container);
  const defaultThumbnailUrl = "https://vrpilot.net/images/your-picture-here.png";

  const appState = useContext(AppStateContext);

  const [storrageAccountName, setStorrageAccountName] = useState("");
  const [identifierName, setIdentifierName] = useState("");
  const [containerName, setContainerName] = useState("");
  const [fileList, setFileList] = useState<AzureFileBlobItem[]>([]);

  const [folderPath, setFolderPath] = useState("");
  const [depth, setDepth] = useState(0);

  const [manifest, setManifest] = useState<string[]>([]);

  // ui control
  const [backButton, setBackButton] = useState(false);
  const [addFolder, setAddFolder] = useState(false);
  const [showThumbControl, setSetThumbControl] = useState(false);
  const [showVideoUpload, setShowVideoUpload] = useState(false);
  const [canUploadThumbnail, setCanUploadThumbnail] = useState(false);
  const [canDeleteThumbnail, setCanDeleteThumbnail] = useState(false);
  const [newFolderValue, setNewFolderValue] = useState("");

  // ui variables
  const [screenFolderList, setScreenFolderList] = useState<ScreenFolder[]>([]);
  const [screenFileList, setScreenFileList] = useState<ScreenFile[]>([]);
  const [thumbnailSASUrl, setThumbnailSASUrl] = useState(defaultThumbnailUrl);
  const [thumbnailUrl, setThumbnailUrl] = useState(defaultThumbnailUrl);
  const [thumbailText, setThumbnailText] = useState("");

  useEffect(() => {
    setIdentifierName((prev) => identifier);
    setContainerName((prev) => container);

    load();
  }, [identifierName, identifier, container, containerName]);

  async function load() {
    if (appState) {
      appState.setState({ spinner: true });
    }
    try {
      const accountData = await azuresasAccountService.get(identifierName);
      setStorrageAccountName((prev) => accountData.storageaccount);

      if (containerName === "") return; // initialization error for some reason

      const list = await azuresasListblobService.list(identifierName, accountData.storageaccount, containerName, true);
      setFileList(list);
      setScreenFolderList([]);
      setScreenFileList([]);
      setFolderPath("");
      setDepth(0);
      await search(list, "", 0);

      //console.log("accountData.storageaccount = " + accountData.storageaccount);
      if (accountData.storageaccount === "") await load();
      const manifest = await azuresasManifestService.get(identifierName, accountData.storageaccount, containerName);
      //console.log(manifest);
      setManifest(manifest);
    } catch (err) {
      console.log(err);
    }
    if (appState) {
      appState.setState({ spinner: false });
    }
  }

  function localOnUploadProgress(file: File, bytes: number): void {
    //console.log("onUploadProgress -> " + file.name + " ... " + bytes + " bytes uploaded!");

    let percentage = Math.round((bytes / file.size) * 100);
    if (percentage === 100) {
      onUploadDone();
    } else {
      onUploadProgress(percentage);
    }
  }

  function onError() {}

  async function localOnUploadCompleted(azureUrl: string) {}

  function localOnUploadStarted() {
    onUploadStarted();
  }

  // "create" a folder by uploading a fake file that we do not show anywhere!
  async function createFolder(newFolderName: string): Promise<void> {
    if (newFolderName === newFolderValue) {
      return;
    }

    try {
      let absFolderPath = folderPath;

      // remove potentional / that might be a issue (not sure?!)
      if (absFolderPath.charAt(0) === "/") {
        absFolderPath = absFolderPath.substring(1);
      }

      absFolderPath = absFolderPath + "/" + newFolderName;

      if (absFolderPath.charAt(0) === "/") absFolderPath = absFolderPath.substring(1);

      //console.log("upload 'folder' that is just a dummy file ... " + absFolderPath);
      let blob = new Blob(["empty"], { type: "text/html" });
      const blobPart: BlobPart[] = [blob];
      const file = new File(blobPart, "empty.empty");

      await uploadAzureFileService.execute(localOnUploadProgress, localOnUploadCompleted, localOnUploadStarted, onError, identifierName, containerName, file, absFolderPath);

      setFolderPath(absFolderPath);
    } catch (error) {
      console.log(error);
    }
    setNewFolderValue(newFolderName);
  }

  async function search(list: AzureFileBlobItem[], path: string, level: number) {
    if (path.charAt(0) === "/") path = path.substring(1); // remove / if we have this!

    setSetThumbControl(false);
    setShowVideoUpload(false);
    const subset = list.filter((element) => {
      return element.name.startsWith(path);
    });

    let folderresult: ScreenFolder[] = [];
    let fileresult: ScreenFile[] = [];

    subset.forEach((element) => {
      const value = element.name.split("/")[level];
      if (value === undefined) return;

      const fileExtensionDefinition = ["mp4", "png", "empty", "jpeg", "jpg"];
      let isFile = false;
      const scanAsFile = value.split(".").pop() ?? "undefined";

      if (fileExtensionDefinition.includes(scanAsFile)) {
        isFile = true;
      }

      if (!isFile) {
        const valuefound = folderresult.filter((element) => element.text === value);
        if (valuefound.length === 0) {
          const item: ScreenFolder = { text: value };
          folderresult.push(item);
        }
      }

      if (isFile) {
        if (value.toLocaleLowerCase() === "empty.empty") {
        } else if (value.toLocaleLowerCase().startsWith("thumbnail")) {
        } else {
          const valuefound = fileresult.filter((element) => element.text === value);
          if (valuefound.length === 0) {
            const item: ScreenFile = { text: value };
            fileresult.push(item);
          }
        }
      }
    });

    const viewHasFolders = folderresult.length !== 0; // true if there are folders!

    setShowVideoUpload(!viewHasFolders);
    setScreenFolderList(folderresult);
    setScreenFileList(fileresult);

    const listOfThumbnails = subset.filter((element) => element.name.indexOf("thumbnail") > 0);

    let hasThumbnail = false;
    if (!viewHasFolders) {
      hasThumbnail = listOfThumbnails.length !== 0;
    }

    setAddFolder(!hasThumbnail);
    setSetThumbControl(!viewHasFolders);
    setCanDeleteThumbnail(hasThumbnail);
    setCanUploadThumbnail(!hasThumbnail);

    if (hasThumbnail) {
      setThumbnailUrl(listOfThumbnails[0].url);
      setThumbnailSASUrl(listOfThumbnails[0].sasurl);
      setThumbnailText(listOfThumbnails[0].name);
    } else {
      setThumbnailUrl(defaultThumbnailUrl);
      setThumbnailSASUrl(defaultThumbnailUrl);
      setThumbnailText("placekitten");
    }

    if (level === 0) {
      setFolderPath("");
      setBackButton(false);
      setSetThumbControl(false);
      setShowVideoUpload(false);
    } else {
      const onlyNewElementOfPath = path.split("/").pop() ?? "undefined";
      if (onlyNewElementOfPath !== "back") {
        setFolderPath(onlyNewElementOfPath);
        setBackButton(true);
      } else {
        setFolderPath((prev) => prev.split("/").pop() ?? [].join("/"));
        setBackButton(true);
      }
    }
  }

  async function deleteFolderRecursive(text: string) {
    let searchPath = "";
    const completeSearch = folderPath + "/" + text;

    if (completeSearch.charAt(0) === "/") {
      searchPath = completeSearch.substring(1);
    } else {
      searchPath = completeSearch;
    }

    const filesInFolderToDelete = fileList.filter((element) => element.name.startsWith(searchPath));

    filesInFolderToDelete.map(async (element) => {
      await deleteAzureFileService.execute(identifierName, containerName, element.name);
    });

    // remove files from manifest file
    let newList: string[] = [];
    let orginal = await azuresasManifestService.get(identifierName, storrageAccountName, containerName);

    orginal.map((orginalManifestEntry) => {
      const key = orginalManifestEntry.split(";")[1].substring(2); // eg. vrpilot01.blob.core.windows.net/video/a1/demo1.mp4
      const deleteItAsItWasFoundAndShouldNotBeAdded = filesInFolderToDelete.filter((filesInFolderToDeleteEntry) => filesInFolderToDeleteEntry.url.endsWith(key));

      if (deleteItAsItWasFoundAndShouldNotBeAdded.length === 0) {
        newList.push(orginalManifestEntry);
      }
      return orginalManifestEntry;
    });

    await azuresasManifestService.set(identifierName, storrageAccountName, containerName, newList);
    setScreenFolderList((prev) => prev.filter((element) => element.text !== text));

    reloadParent();
  }

  async function deleteFile(text: string) {
    let absFilePath = folderPath + "/" + text;
    if (absFilePath.charAt(0) === "/") absFilePath = absFilePath.substring(1);
    await deleteAzureFileService.execute(identifierName, containerName, absFilePath);

    await removeFromManifest(absFilePath);
    await load();
    reloadParent();
  }

  async function deleteThumbnail(text: string) {
    let absFilePath = text;
    if (absFilePath.charAt(0) === "/") absFilePath = absFilePath.substring(1);
    await deleteAzureFileService.execute(identifierName, containerName, absFilePath);
    await load();
  }

  async function uploadThumbnail(items: FileList) {
    const file = items[0];
    let absFilePath = folderPath;
    if (absFilePath.charAt(0) === "/") absFilePath = absFilePath.substring(1);
    await uploadAzureFileService.execute(localOnUploadProgress, localOnUploadCompleted, localOnUploadStarted, onError, identifierName, containerName, file, absFilePath);

    // rename file if the file does not have the name "thumbnail"
    if (!file.name.startsWith("thumbnail")) {
      const result = await renameAzureFileService.execute(identifierName, containerName, absFilePath, file.name, "thumbnail");
      console.log(result);
    }

    await load();
  }

  function changeDirectoryTo(enterFolder: string) {
    let absPath = folderPath;

    let level = 0;
    if (enterFolder === "back") {
      level = depth - 1;
      var splitted = folderPath.split("/");
      var toRemoveFromFolderPath = splitted[splitted.length - 1];
      absPath = absPath.replace("/" + toRemoveFromFolderPath, "");
      if (level === 0) {
        absPath = "";
      }
    } else {
      absPath = absPath + "/" + enterFolder;
      level = depth + 1;
    }

    search(fileList, absPath, level);
    setDepth(level);
    setFolderPath(absPath);
  }

  async function uploadVideoFile(fileoption: string, file: File) {
    if (fileoption !== "none") {
      let absFolderPath = folderPath;
      if (absFolderPath.charAt(0) === "/") absFolderPath = absFolderPath.substring(1);
      const azureUrl = await uploadAzureFileService.execute(localOnUploadProgress, localOnUploadCompleted, localOnUploadStarted, onError, identifierName, containerName, file, absFolderPath);

      await addToManifest(fileoption, azureUrl);
      await load();
    }
  }

  async function addToManifest(filetype: string, url: string) {
    let orginal = await azuresasManifestService.get(identifierName, storrageAccountName, containerName);
    orginal.push(filetype + ";" + url);
    await azuresasManifestService.set(identifierName, storrageAccountName, containerName, orginal);
    //const modified = await azuresasManifestService.get(identifierName, storrageAccountName, containerName);
    //console.log(modified);
  }

  async function removeFromManifest(url: string) {
    let orginal = await azuresasManifestService.get(identifierName, storrageAccountName, containerName);
    const excluded = orginal.filter((element) => !element.endsWith(url));
    await azuresasManifestService.set(identifierName, storrageAccountName, containerName, excluded);
    //const modified = await azuresasManifestService.get(identifierName, storrageAccountName, containerName);
    //console.log(modified);
  }

  const renderFolders = screenFolderList.map((element, index) => {
    return (
      <FolderBox
        key={index}
        id={element.text}
        text={element.text}
        onClick={function (id: string, text: string): void {
          changeDirectoryTo(text);
        }}
        secondarytext={"delete"}
        onClickSecondary={function (id: string, text: string): void {
          deleteFolderRecursive(text);
        }}
      />
    );
  });

  //console.log(screenFileList);

  const renderFiles = screenFileList.map((element, index) => {
    const manifestEntry = manifest.filter((item) => {
      return item.endsWith(element.text);
    })[0];

    if (manifestEntry === undefined) {
      console.log("UNDEFINED");
      return;
    }

    const fileType = manifestEntry.split(";")[0];

    return (
      <FileBox
        key={index}
        id={element.text}
        text={element.text}
        secondarytext={"delete"}
        filetype={fileType}
        onClick={function (id: string, text: string): void {
          //console.log(text);
        }}
        onClickSecondary={function (id: string, text: string): void {
          deleteFile(text);
        }}
      />
    );
  });

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.column1}>
          {renderFolders}
          {renderFiles}
          {backButton && (
            <SquareBox
              id={"back"}
              text={"BACK"}
              onClick={function (id: string, text: string): void {
                changeDirectoryTo("back");
              }}
              textalign={"left"}
            />
          )}
          {addFolder && (
            <SquareTextBox
              id={"add"}
              text={"+ add folder here"}
              size={15}
              defaultvalue={""}
              onClick={function (id: string, text: string, value: string): void {
                createFolder(value);
              }}
            />
          )}
        </div>
        <div className={classes.column2}>
          <div>
            {showThumbControl && (
              <ThumbnailControl
                onClickDelete={(text) => {
                  deleteThumbnail(text);
                }}
                onClickUpload={(items) => {
                  uploadThumbnail(items);
                }}
                text={thumbailText}
                url={thumbnailUrl}
                sasurl={thumbnailSASUrl}
                candelete={canDeleteThumbnail}
                canupload={canUploadThumbnail}
              />
            )}
          </div>
        </div>
        <div className={classes.column3}>{showVideoUpload && <UploadVideoFileToAzureControl onUploadVideoFile={uploadVideoFile} />}</div>
      </div>
    </div>
  );
};
