import { FileBoxProps } from "../../../types/filebox.types";
import classes from "./FileBox.module.css";

export const FileBox = ({ onClick, onClickSecondary, id, text, secondarytext, filetype }: FileBoxProps) => {
  function localOnClick() {
    onClick(id, text);
  }
  function localOnClickSecondary() {
    onClickSecondary(id, text);
  }
  return (
    <div className={classes.row}>
      <div className={classes.filetype}>[{filetype}]</div>
      <div title="file" className={[classes.column1].join(" ")} onClick={localOnClick}>
        {text}
      </div>
      <div title="action" className={[classes.column2].join(" ")} onClick={localOnClickSecondary}>
        {secondarytext}
      </div>
    </div>
  );
};
