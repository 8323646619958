import axios from "../../api/axios.private";

class OrganizationAddService {
  async create(name: string, identifier: string, storage_name: string, access_key: string) {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/organization/create";
    const data = {
      name: name,
      identifier: identifier,
      storage_name: storage_name,
      access_key: access_key,
    };

    await axios
      .post(url, data, config)
      .then((res) => {})
      .catch((err) => console.log(err));
    //.catch((err) => {});
  }
}

const instance = new OrganizationAddService();
export default instance;
