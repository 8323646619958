import axios from "../../api/axios.private";
import { BackendAzureSASAccountItem } from "../../types/azuresas.types";
import sharedUtilDelay from "../shared/shared.util.delay";

class AzureSASAccountService {
  async get(identifier: string): Promise<BackendAzureSASAccountItem> {
    let result: BackendAzureSASAccountItem = { storageaccount: "" };

    await sharedUtilDelay.delay(500); // the server thinks it's a Dos attack?!

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    // NOTE: identifier is empty sometimes ... this is a state issue!
    if (identifier === "") {
      return result;
    }

    const url = "/ui/azuresas/account";
    const data = {
      identifier: identifier,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new AzureSASAccountService();
export default instance;
