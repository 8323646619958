import classes from "./ContainerApplicationHeaderPrincipalItem.module.css";

export const ContainerApplicationHeaderPrincipalItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>File</div>
      <div className={classes.action}>Action</div>
    </div>
  );
};
