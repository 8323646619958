import classes from "./Card.module.css";

export interface CardProps {
  children: React.ReactNode;
  size: string;
}

export const Card: React.FunctionComponent<CardProps> = ({ size, children }) => {
  if (size === "small") {
    return <div className={classes.cardSmall}>{children}</div>;
  } else if (size === "large") {
    return <div className={classes.cardLong}>{children}</div>;
  }
  return <div>ERROR IN CARD COMPONENT - {size}</div>;
};

export default Card;
