export interface BackendTraningEntry {
  username: string;
  items: BackendTrainingItem[];
}

export interface BackendTrainingItem {
  id: number;
  syncron: string;
  session_id: string;
  identifier: string;
  username: string;
  cockpitname: string;
  procedurename: string;
  trainingmode: number;
  evaltime: number;
  errortracking: number;
  errorcount: number;
  logintime: string;
}

export enum ConverTo {
  CSV,
  JSON,
}
