import classes from "./AdminHeaderItem.module.css";

export const AdminHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username}>Username</div>
      <div className={classes.canaccessvideo}>Video</div>
      <div className={classes.canaccessapplication}>App</div>
      <div className={classes.haspassword}>Temp password</div>
      <div className={classes.active}>Active</div>
      <div className={classes.canexpire}>Can expire</div>
      <div className={classes.expireson}>Expires on</div>
      <div className={classes.edit}>Edit</div>
    </div>
  );
};
