import classes from "./UploadVideoFileToAzureControl.module.css";
import { FileSelect } from "../../form/fileselect/FileSelect";
import { UploadVideoFileToAzureControlProps } from "../../../types/common.types";
import { useState } from "react";

export const UploadVideoFileToAzureControl = ({ onUploadVideoFile }: UploadVideoFileToAzureControlProps) => {
  const [videotype, setVideType] = useState("360");
  function localOnChangeValue(e: React.FormEvent<HTMLDivElement>) {
    var target = e.target as HTMLSelectElement;
    setVideType(target.value);
  }
  return (
    <div className={classes.edgebox}>
      <h2>Upload video file</h2>
      <div className={classes.left} onChange={localOnChangeValue}>
        <br />
        <input type="radio" name="radio-group" value="360" defaultChecked />
        360 degree video
        <br />
        <br />
        <input type="radio" name="radio-group" value="2D" />
        Regular video
      </div>
      <div>
        <FileSelect
          acceptFileExtensionList={["mp4"]}
          onFilesSelected={function (items: FileList): void {
            onUploadVideoFile(videotype, items[0]);
          }}
          text={"Upload"}
          disabled={false}
        />
      </div>
      <p>You can use mp4 video files</p>
    </div>
  );
};
