import axios from "../../api/axios.private";
import { BackendUser } from "../../types/users.types";

class UsersGetService {
  async get(id: number): Promise<BackendUser> {
    let result: BackendUser = {
      active: 0,
      can_expire: 0,
      expires_on: "0001-01-01",
      has_temp_password: 0,
      hash: "",
      id: 0,
      organization_id: "",
      type: "",
      username: "",
      can_access_application: 0,
      can_access_video: 0,
      api_allow_multiple_login: 0,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/users/get";
    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.data;
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
    //.catch((err) => {});

    return result;
  }
}

const instance = new UsersGetService();
export default instance;
