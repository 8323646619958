import classes from "./TwoFactorAutenticationSetupPage.module.css";
import twofaVerifyService from "../../../services/autentication/twofa.verify.service";
import twofaSetupService from "../../../services/autentication/twofa.setup.service";
import twofaCompleteService from "../../../services/autentication/twofa.complete.service";
import QRCode from "qrcode";
import sharedUtilOtpauth from "../../../services/shared/shared.util.otpauth";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../ui/button/Button";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

export const TwoFactorAutenticationSetupPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  //const canvasRef = useRef<HTMLCanvasElement>(null);
  const [userId, setUserId] = useState(0);
  const [showQr, setShowQR] = useState(false);
  const [text, setText] = useState("");
  const [username, setUsername] = useState("");
  const twofsInputRef = useRef<HTMLInputElement>(null);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (userContext && userContext.user && userContext.user.id) {
      setUserId(userContext.user.id);
      setUsername(userContext.user.name);
    }
  }, [text, userContext]);

  async function verify2fa() {
    const twofa = twofsInputRef.current ? twofsInputRef.current.value : "";
    if (twofa) {
      const verified = await twofaVerifyService.verify(username, twofa);
      if (verified === 1) {
        setVerificationMessage("SUCCESS ... your 2FA works");
        setShowBackButton(true);
      } else {
        setVerificationMessage("FAILED");
        setShowBackButton(false);
      }
    } else {
      setVerificationMessage("");
    }
  }

  async function setup() {
    const secret = await twofaSetupService.setup(userId);
    setShowQR(true);
    const otpauth = sharedUtilOtpauth.generate(username, secret.two_fa_secret);

    var canvas = document.getElementById("canvas");
    QRCode.toCanvas(canvas, text || otpauth, (error: any) => error && console.error(error));
  }

  async function completesetup() {
    await twofaCompleteService.complete(userId);
    navigate("/home");
  }

  return (
    <div>
      <div className={classes.container}>
        <h1>Welcome to the 2FA setup</h1>
        <div>{showQr && <p>Please scan this QR code in your autenticator mobile app</p>}</div>

        {!showQr && (
          <div>
            <br />
            <Button text="Configure" onClick={setup} />
          </div>
        )}
        {/* <div>{showQr && <canvas ref={canvasRef} />}</div> */}
        <div>
          <canvas id="canvas" />
        </div>
        <div>
          {showQr && (
            <div>
              <div className={classes.control}>
                <label htmlFor="2fa">Code: </label>
                <input data-lpignore="true" type="text" required id="2fa" autoComplete="off" ref={twofsInputRef} placeholder="Insert your token" pattern="[0-9]{6}" />
                <Button text="Verify" onClick={verify2fa} />
                <br />
                <br />
                <p>{verificationMessage}</p>
                <br />
                {showBackButton && <Button text="Great!" onClick={completesetup} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
