import React, { useState, createContext } from "react";

export type AppState = {
  spinner: boolean;
};

type AppStateProviderProps = {
  children: React.ReactNode;
};

type AppStateType = {
  state: AppState | null;
  setState: React.Dispatch<React.SetStateAction<AppState | null>>;
};

export const AppStateContext = createContext<AppStateType | null>(null);

export const AppStateProvider = ({ children }: AppStateProviderProps) => {
  const [state, setState] = useState<AppState | null>(null);
  return <AppStateContext.Provider value={{ state, setState }}>{children}</AppStateContext.Provider>;
};
