import axios from "../../api/axios.private";

class TwoFACompleteService {
  async complete(id: number): Promise<number> {
    let result: number = 0;

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/autentication/2fa/complete";

    const data = {
      id: id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        //console.log(res.data);
        result = res.status;
      })
      //.catch((err) => console.log(err));
      .catch((err) => {
        return 0;
      });

    return result;
  }
}

const instance = new TwoFACompleteService();
export default instance;
