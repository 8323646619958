import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { UsersList } from "../../../ui/users/list/UsersList";

export const UsersListPage = () => {
  const userContext = useContext(UserContext);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  return <UsersList />;
};
