import React from "react";
import { Button } from "../../ui/button/Button";
import Card from "../../ui/card/Card";
import classes from "./LogoutForm.module.css";

interface Props {
  doLogout(): void;
}

export const LogoutForm = ({ doLogout }: Props) => {
  function submitHandler(event: { preventDefault: () => void }) {
    event.preventDefault();
    doLogout();
  }

  return (
    <Card size="small">
      <form className={classes.form} onSubmit={submitHandler}>
        <p>You are about to logout?</p>
        <div className={classes.actions}>
          <label htmlFor="logout"></label>
          <Button text="Logout" onClick={submitHandler} />
        </div>
      </form>
    </Card>
  );
};

export default LogoutForm;
