import classes from "./AuditlogHeaderItem.module.css";

export const AuditlogHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.syncron}>Syncron</div>
      <div className={classes.username}>Username</div>
      <div className={classes.actionperformed}>Action performaed</div>
      <div className={classes.extra}>Extra</div>
    </div>
  );
};
