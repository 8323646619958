export enum AZURESAS_TOKEN_EXPIRATION {
  SHORT = "1 minute",
  LONG = "1 hour",
  VERY_LONG = "6 hours",
}

export interface InputFileProps {
  disabled: boolean;
  text: string;
  acceptFileExtensionList: string[];
  onFilesSelected(items: FileList): void;
}

export interface UploadVideoFileToAzureControlProps {
  onUploadVideoFile(fileoption: string, file: File): void;
}
