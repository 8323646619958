import classes from "./ContainerApplicationPrincipalList.module.css";
import azuresasAccountService from "../../../../services/azuresas/azuresas.account.service";
import azuresasListblobService from "../../../../services/azuresas/azuresas.listblob.service";
import azuresasDownloadService from "../../../../services/azuresas/azuresas.download.service";
import { useContext, useEffect, useState } from "react";
import { ContainerApplicationPrincipalListProps, ContainerVideoPrincipalItemProps } from "../../../../types/principal.types";
import { AzureFileBlobItem } from "../../../../types/azuresas.types";
import { ContainerApplicationPrincipalItem } from "./ContainerApplicationPrincipalItem";
import { ContainerApplicationHeaderPrincipalItem } from "./ContainerApplicationHeaderPrincipalItem";
import Modal from "../../modal/Modal";
import useModal from "../../../form/hooks/useModal";
import { Button } from "../../button/Button";
import { AppStateContext } from "../../../../context/AppStateContext";

export const ContainerApplicationPrincipalList = ({ identifier, container }: ContainerApplicationPrincipalListProps) => {
  const [containerName, setContainerName] = useState("");
  const [fileList, setFileList] = useState<AzureFileBlobItem[]>([]);
  const { isOpen, toggle } = useModal();
  const [canClose, setCanClose] = useState(false);
  const [actionText, setActionText] = useState("");
  const appState = useContext(AppStateContext);

  useEffect(() => {
    setContainerName(container);
    getFileList();
  }, [container, containerName]);

  async function getFileList() {
    if (appState) {
      appState.setState({ spinner: true });
    }
    const accountData = await azuresasAccountService.get(identifier);
    const list = await azuresasListblobService.list(identifier, accountData.storageaccount, containerName, true);
    setFileList(list);
    if (appState) {
      appState.setState({ spinner: false });
    }
  }

  async function onClickActionDownload(item: AzureFileBlobItem) {
    //console.log(item);
    const accountData = await azuresasAccountService.get(identifier);
    await azuresasDownloadService.downloadArtifact(identifier, accountData.storageaccount, containerName, item.name);
    setCanClose(true);
    setActionText("DONE");
  }

  function togglePopup() {
    if (canClose) {
      toggle();
    }
  }

  const list = fileList.map(function (mapItem: AzureFileBlobItem, id: number) {
    let props: ContainerVideoPrincipalItemProps = {
      item: mapItem,
      onClickActionDownload: (item: AzureFileBlobItem) => {
        //setActionText("DOWNLOADING");
        //setCanClose(false);
        //toggle();
        onClickActionDownload(item);
      },
    };
    return <ContainerApplicationPrincipalItem key={id} {...props} />;
  });

  return (
    <div className={classes.container}>
      <h3>Download the application</h3>
      <div>
        <ContainerApplicationHeaderPrincipalItem />
        {list}
      </div>

      <Modal isOpen={isOpen} toggle={toggle}>
        <div>
          <Button text="X" onClick={togglePopup} />
        </div>
        <div>
          <br />
          <p>Action in pogress...</p>
          <br />
          <p>Please wait until it is a 100% - then you can close this by pressing the X</p>
          <br />
          <p>{actionText}</p>
        </div>
      </Modal>
    </div>
  );
};
