import classes from "./OrganizationHeaderItem.module.css";

export const OrganizationHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.edit}>Edit</div>
      <div className={classes.name}>Name</div>
      <div className={classes.identifier}>Identifier</div>
      <div className={classes.active}>Active</div>
      <div className={classes.users}>Users</div>
      <div className={classes.admins}>Admins</div>
      <div className={classes.auditlog}>Auditlog</div>
      <div className={classes.training}>Training</div>
      <div className={classes.storagevideo}>Video</div>
      <div className={classes.storageapplication}>App</div>
    </div>
  );
};
