import MainNavigation from "./MainNavigation";
import HomePage from "../../pages/home/HomePage";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { OrganizationEditPage } from "../../pages/organization/edit/OrganizationEditPage";
import { OrganizationAddPage } from "../../pages/organization/add/OrganizationAddPage";
import { UsersListPage } from "../../pages/users/list/UsersListPage";
import { UserEditPage } from "../../ui/users/edit/UserEditPage";
import { UserAddPage } from "../../ui/users/add/UserAddPage";
import { AdminListPage } from "../../pages/admin/list/AdminListPage";
import { AdminEditPage } from "../../ui/admin/edit/AdminEditPage";
import { AdminAddPage } from "../../ui/admin/add/AdminAddPage";
import { AuditLogPage } from "../../pages/auditlog/AuditLogPage";
import { AzureStorageVideoPage } from "../../pages/storage/AzureStorageVideoPage";
import { AzureStorageApplication } from "../../pages/storage/AzureStorageApplication";
import { HomePrincipalPage } from "../../pages/home/principal/HomePrincipalPage";
import { HomePrincipalSetNewPassword } from "../../pages/home/principal/HomePrincipalSetNewPassword";
import { UserEditPrincipalPage } from "../../ui/users/edit/principal/UserEditPrincipalPage";
import { AzureStoragePrincipalDownloadPage } from "../../pages/storage/principal/download/AzureStoragePrincipalDownloadPage";
import { UserAddPrincipalPage } from "../../ui/users/add/principal/UserAddPrincipalPage";
import { AzureStoragePrincipalVideoPage } from "../../pages/storage/principal/video/AzureStoragePrincipalVideoPage";
import usersGetService from "../../../services/users/users.get.service";
import { AdminEditResetAdminPassword } from "../../ui/admin/edit/AdminEditResetAdminPassword";
import { TwoFactorAutenticationSetupPage } from "../../pages/twofa/TwoFactorAutenticationSetupPage";
import secureLocalStorage from "react-secure-storage";

interface LayoutProps {
  page: string;
}

export const Layout = ({ page }: LayoutProps) => {
  const userContext = useContext(UserContext);
  const [hasTempPassword, setHasTempPassword] = useState(false);

  useEffect(() => {
    if (userContext) {
      if (!userContext.user) {
        const strinigifiedJson: string = secureLocalStorage.getItem("state") as string;
        if (strinigifiedJson) {
          const authUser = JSON.parse(strinigifiedJson);
          userContext.setUser(authUser);
        }
      }
    }
    if (userContext && userContext.user && userContext.user.type && userContext.user.type === "admin") {
      usersGetService.get(userContext.user.id).then((res) => {
        setHasTempPassword(res.has_temp_password === 1 ? true : false);
      });
    }
  }, [userContext]);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
  }

  const userType = userContext?.user?.type;

  return (
    <div>
      <MainNavigation />
      <main>{page === "home" && userType === "sysadmin" && <HomePage />}</main>
      <main>{page === "home" && userType === "admin" && hasTempPassword && <HomePrincipalSetNewPassword />}</main>
      <main>{page === "home" && userType === "admin" && !hasTempPassword && <HomePrincipalPage />}</main>
      <main>{page === "2fa-setup" && userType === "admin" && <TwoFactorAutenticationSetupPage />}</main>
      <main>{page === "organization-edit" && userType === "sysadmin" && <OrganizationEditPage />}</main>
      <main>{page === "organization-add" && userType === "sysadmin" && <OrganizationAddPage />}</main>
      <main>{page === "users-list" && userType === "sysadmin" && <UsersListPage />}</main>
      <main>{page === "user-edit" && userType === "sysadmin" && <UserEditPage />}</main>
      <main>{page === "user-edit" && userType === "admin" && <UserEditPrincipalPage />}</main>
      <main>{page === "user-add" && userType === "sysadmin" && <UserAddPage />}</main>
      <main>{page === "user-add" && userType === "admin" && <UserAddPrincipalPage />}</main>
      <main>{page === "admin-list" && userType === "sysadmin" && <AdminListPage />}</main>
      <main>{page === "admin-edit" && userType === "sysadmin" && <AdminEditPage />}</main>
      <main>{page === "admin-add" && userType === "sysadmin" && <AdminAddPage />}</main>
      <main>{page === "admin-password-reset" && userType === "sysadmin" && <AdminEditResetAdminPassword />}</main>
      <main>{page === "auditlog-list" && userType === "sysadmin" && <AuditLogPage />}</main>
      <main>{page === "azure-storage-video" && userType === "sysadmin" && <AzureStorageVideoPage />}</main>
      <main>{page === "azure-storage-application" && userType === "sysadmin" && <AzureStorageApplication />}</main>
      <main>{page === "azure-storage-principal-download-application" && userType === "admin" && <AzureStoragePrincipalDownloadPage />}</main>
      <main>{page === "azure-storage-principal-manage-video" && userType === "admin" && <AzureStoragePrincipalVideoPage />}</main>
    </div>
  );
};

export default Layout;
