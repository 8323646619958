import classes from "./ContainerVideoHeaderOrganizeItem.module.css";

export const ContainerVideoHeaderOrganizeItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>File</div>
      <div className={classes.organize}>Organize</div>
    </div>
  );
};
  