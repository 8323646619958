import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usersListService from "../../../../services/users/users.list.service";
import { BackendOrganizationItem } from "../../../../types/organization.types";
import { BackendUser } from "../../../../types/users.types";
import { Button } from "../../button/Button";
import { UserHeaderItem } from "./UserHeaderItem";
import { UserItem } from "./UserItem";
import classes from "./UsersList.module.css";

export const UsersList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [locationState, setLocationState] = useState<BackendOrganizationItem>();
  const [users, setUsers] = useState<BackendUser[]>();

  useEffect(() => {
    setLocationState(location.state);
    usersListService.list(location.state.organization_id).then((res) => {
      setUsers(res);
    });
  }, [location.state]);

  function onClickEdit(item: BackendUser) {
    navigate("/user/edit", { state: item });
  }

  function onClickAdd() {
    navigate("/user/add", { state: locationState });
  }

  const list = users?.map(function (item: BackendUser, id: number) {
    return <UserItem key={item.id} item={item} onClick={onClickEdit} />;
  });

  return (
    <div className={classes.container}>
      <h1>Users for [{locationState?.name}]</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <Button text="Add a user" onClick={onClickAdd} />
      <br />
      <br />
      <UserHeaderItem />
      {list}
    </div>
  );
};
