import axios from "../../api/axios.private";

class OrganizationEditService {
  async edit(id: number, name: string, identifier: string, active: number, container_app: string, container_video: string, storage_name: string, access_key: string) {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/organization/edit";
    const data = {
      id: id,
      name: name,
      identifier: identifier,
      active: active,
      container_app: container_app,
      container_video: container_video,
      storage_name: storage_name,
      access_key: access_key,
    };

    await axios
      .post(url, data, config)
      .then((res) => {})
      .catch((err) => console.log(err));
    //.catch((err) => {});
  }
}

const instance = new OrganizationEditService();
export default instance;
