import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackendAuditlogItem } from "../../../types/auditlog.types";
import { BackendOrganizationItem } from "../../../types/organization.types";
import classes from "./AuditlogList.module.css";
import auditlogListService from "../../../services/auditlog/auditlog.list.service";
import { AuditlogItem } from "./AuditlogItem";
import { Button } from "../button/Button";
import { AuditlogHeaderItem } from "./AuditlogHeaderItem";

export const AuditlogList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [locationState, setLocationState] = useState<BackendOrganizationItem>();
  const [logs, setLogs] = useState<BackendAuditlogItem[]>();

  useEffect(() => {
    setLocationState(location.state);
    auditlogListService.list(location.state.organization_id).then((res) => {
      setLogs(res);
    });
  }, [location.state]);

  const list = logs?.map(function (item: BackendAuditlogItem, id: number) {
    return <AuditlogItem key={id} item={item} />;
  });

  return (
    <div className={classes.container}>
      <h1>Auditlog for [{locationState?.name}]</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <AuditlogHeaderItem />
      {list}
    </div>
  );
};
