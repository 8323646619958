import { ContainerVideoOrganizeItemProps } from "../../../types/filemanager.types";
import classes from "./ContainerVideoOrganizeItem.module.css";

export const ContainerVideoOrganizeItem = ({ onClickUp, onClickDown, item, displaytext, azurefile }: ContainerVideoOrganizeItemProps) => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>{displaytext}</div>
      <div className={classes.organize}>
        <div
          className={classes.up}
          onClick={() => {
            onClickUp(item);
          }}
        >
          up
        </div>
        <div
          className={classes.down}
          onClick={() => {
            onClickDown(item);
          }}
        >
          down
        </div>
      </div>
    </div>
  );
};
