import { SquareBoxProps } from "../../../types/squarebox.types";
import classes from "./SquareBox.module.css";

export const SquareBox = ({ onClick, textalign, id, text }: SquareBoxProps) => {
  function localOnClick() {
    onClick(id, text);
  }
  return (
    <div key={id}>
      {textalign === "center" && (
        <div className={classes.boxcentertext} onClick={localOnClick}>
          {text}
        </div>
      )}
      {textalign === "left" && (
        <div className={classes.boxlefttext} onClick={localOnClick}>
          {text}
        </div>
      )}
    </div>
  );
};
