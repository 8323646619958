import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { Button } from "../../ui/button/Button";
import { ContainerApplicationList } from "../../ui/containerapplication/ContainerApplicationList";
import classes from "./AzureStorageApplication.module.css";

export const AzureStorageApplication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const userContext = useContext(UserContext);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  function uploadComplete() {
    setUpdate(!update);
  }

  return (
    <div className={classes.container}>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <h1>Applications</h1>
      <br />
      <ContainerApplicationList identifier={location.state.identifier} container={location.state.container_app} onUploadComplete={uploadComplete} />
    </div>
  );
};
