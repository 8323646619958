import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./context/UserContext";
import { Layout as AutenticatedLayout } from "./components/layout/authenticated/Layout";
import LoginPage from "./components/pages/login/LoginPage";
import LogoutPage from "./components/pages/logout/LogoutPage";
import MissingPage from "./components/pages/missing/MissingPage";
import ContentWrapper from "./components/wrapper/ContentWrapper";
import { AppStateProvider } from "./context/AppStateContext";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <AppStateProvider>
      <UserContextProvider>
        <ContentWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/home" element={<AutenticatedLayout page="home" />} />
              <Route path="/2fa-setup" element={<AutenticatedLayout page="2fa-setup" />} />
              <Route path="/organization/edit" element={<AutenticatedLayout page="organization-edit" />} />
              <Route path="/organization/add" element={<AutenticatedLayout page="organization-add" />} />
              <Route path="/users/list" element={<AutenticatedLayout page="users-list" />} />
              <Route path="/user/edit" element={<AutenticatedLayout page="user-edit" />} />
              <Route path="/user/add" element={<AutenticatedLayout page="user-add" />} />
              <Route path="/admin/list" element={<AutenticatedLayout page="admin-list" />} />
              <Route path="/admin/edit" element={<AutenticatedLayout page="admin-edit" />} />
              <Route path="/admin/add" element={<AutenticatedLayout page="admin-add" />} />
              <Route path="/admin/password/reset" element={<AutenticatedLayout page="admin-password-reset" />} />
              <Route path="/auditlog/list" element={<AutenticatedLayout page="auditlog-list" />} />
              <Route path="/azure/sas/list" element={<AutenticatedLayout page="azure-sas-list" />} />
              <Route path="/azure/sas/edit" element={<AutenticatedLayout page="azure-sas-edit" />} />
              <Route path="/azure/sas/add" element={<AutenticatedLayout page="azure-sas-add" />} />
              <Route path="/azure/storage" element={<AutenticatedLayout page="azure-storage" />} />
              <Route path="/azure/storage/video" element={<AutenticatedLayout page="azure-storage-video" />} />
              <Route path="/azure/storage/application" element={<AutenticatedLayout page="azure-storage-application" />} />
              <Route path="/azure/storage/principal/download/application" element={<AutenticatedLayout page="azure-storage-principal-download-application" />} />
              <Route path="/azure/storage/principal/manage/video" element={<AutenticatedLayout page="azure-storage-principal-manage-video" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="*" element={<MissingPage />} />
            </Routes>
          </BrowserRouter>
        </ContentWrapper>
      </UserContextProvider>
    </AppStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
