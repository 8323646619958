import { BlobServiceClient } from "@azure/storage-blob";
import { AzureFileBlobItem } from "../../types/azuresas.types";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";
import azuresasGenerateService from "./azuresas.generate.service";

class AzureSASListBlobService {
  async list(identifier: string, storageaccount: string, containername: string, ignoreManifestFile: boolean): Promise<AzureFileBlobItem[]> {
    let result: AzureFileBlobItem[] = [];
    if (containername.length === 0) {
      return result;
    }
    if (storageaccount.length === 0) {
      return result;
    }
    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);

    for await (const blob of containerClient.listBlobsFlat()) {
      const file: AzureFileBlobItem = {
        name: blob.name,
        sasurl: `https://${storageaccount}.blob.core.windows.net/${containername}/${blob.name}?${sasCredentials.sas}`,
        url: `https://${storageaccount}.blob.core.windows.net/${containername}/${blob.name}`,
      };

      if (ignoreManifestFile) {
        if (file.name !== "manifest.txt") {
          result.push(file);
        }
      } else {
        result.push(file);
      }
    }
    return result;
  }
}

const instance = new AzureSASListBlobService();
export default instance;
