import { BackendOrganizationItemProps } from "../../../types/organization.types";
import classes from "./OrganizationItem.module.css";

export const OrganizationItem = ({ onClick, auditlogs, actives, admins, users, item }: BackendOrganizationItemProps) => {
  function onEditClicked() {
    onClick("organization-edit", item);
  }
  function onAuditlogsClicked() {
    onClick("auditlogs", item);
  }
  function onTrainingClicked() {
    onClick("training", item);
  }
  function onStorageVideoClicked() {
    onClick("azure-storage-video", item);
  }
  function onStorageApplicationClicked() {
    onClick("azure-storage-application", item);
  }
  function onAdminsClicked() {
    onClick("admins", item);
  }
  function onUsersClicked() {
    onClick("users", item);
  }
  function resolveActive(): string {
    return item.active === 1 ? "Yes" : "No";
  }

  return (
    <div className={classes.gridcontainer}>
      <div className={classes.edit} onClick={onEditClicked}>
        <div>Edit</div>
      </div>
      <div className={classes.name}>{item.name}</div>
      <div className={classes.identifier}>{item.identifier}</div>
      <div className={classes.active}>{resolveActive()}</div>
      <div className={classes.users} onClick={onUsersClicked}>
        {users}
      </div>
      <div className={classes.admins} onClick={onAdminsClicked}>
        {admins}
      </div>
      <div className={classes.auditlog} onClick={onAuditlogsClicked}>
        {auditlogs}
      </div>
      <div className={classes.training} onClick={onTrainingClicked}>
        View
      </div>
      <div className={classes.storagevideo} onClick={onStorageVideoClicked}>
        Edit
      </div>
      <div className={classes.storageapplication} onClick={onStorageApplicationClicked}>
        Edit
      </div>
    </div>
  );
};
