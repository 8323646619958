import { useContext, useEffect } from "react";
import classes from "./MainNavigation.module.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

export const MainNavigation = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // if the user creds are not valid send the user to the login screen
    if (userContext && userContext.user) {
      if (userContext.user.id === 0 && userContext.user.organizationid === "" && userContext.user.type === "") {
        navigate("/");
      }
    }
  });

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
  }

  return (
    <header className={classes.header}>
      <div className={classes.welcome}>
        Hi {userContext?.user?.name} ({userContext?.user?.type})
      </div>
      <div className={classes.logo}></div>
      <nav>
        <ul>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/logout">Logout</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
