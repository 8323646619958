import { BlobServiceClient } from "@azure/storage-blob";
import { AzureFileBlobItem } from "../../types/azuresas.types";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";
import azuresasGenerateService from "./azuresas.generate.service";

class AzureSASDownloadSerivce {
  async blobToString(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      fileReader.onloadend = (ev: any) => {
        resolve(ev.target!.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }
  async blobToBinary(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      fileReader.onloadend = (ev: any) => {
        resolve(ev.target!.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(blob);
    });
  }
  async get(identifier: string, storageaccount: string, containername: string): Promise<AzureFileBlobItem[]> {
    let result: AzureFileBlobItem[] = [];

    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);

    const blobClient = containerClient.getBlobClient("manifest.txt");
    const downloadBlockBlobResponse = await blobClient.download();
    const blobBody = await downloadBlockBlobResponse.blobBody;
    if (blobBody) {
      const manifest = await this.blobToString(blobBody);
      const manifestLines = manifest.split("\n");
      manifestLines.map((line) => {
        //console.log(line);
        return line;
      });
    } else {
      console.log("empty");
    }

    return result;
  }
  async downloadArtifact(identifier: string, storageaccount: string, containername: string, filename: string) {
    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);

    const blobClient = containerClient.getBlobClient(filename);
    const download = await blobClient.download();
    //console.log(blobClient.url);

    //console.log(download);
    const blobData: Blob | undefined = await download.blobBody;
    if (blobData) {
      const downloaded = await this.blobToBinary(blobData);
      const blob = new Blob([downloaded], { type: download.contentType });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    }
  }
}

const instance = new AzureSASDownloadSerivce();
export default instance;
