import axios from "../../api/axios.private";

class TwoFAVerifyService {
  async verify(username: string, code: string): Promise<number> {
    let result: number = 0;

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/autentication/2fa/verify";

    const data = {
      username: username,
      code: code,
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        //console.log(res.data);
        result = res.data;
      })
      //.catch((err) => console.log(err));
      .catch((err) => {
        return 0;
      });

    return result;
  }
}

const instance = new TwoFAVerifyService();
export default instance;
