import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import azuresasAccountService from "../azuresas/azuresas.account.service";
import azuresasGenerateService from "../azuresas/azuresas.generate.service";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";

class RenameAzureFileService {
  async execute(identifier: string, containerName: string, absFilePath: string, existingName: string, newName: string): Promise<boolean> {
    const accountData = await azuresasAccountService.get(identifier);
    const sasCredentials = await azuresasGenerateService.generate(identifier, containerName, AZURESAS_TOKEN_EXPIRATION.LONG);
    const blobService = new BlobServiceClient(`https://${accountData.storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containerName);

    const existingFileExtension = existingName.split(".")[1];
    const existingFileName = absFilePath + "/" + existingName;
    const newFileName = absFilePath + "/" + newName + "." + existingFileExtension;

    this.renameBlob(containerClient, existingFileName, newFileName);
    return true;
  }

  async renameBlob(containerClient: ContainerClient, existingName: string, newName: string) {
    // assume blob exists and names are different
    const blobClient = containerClient.getBlobClient(existingName);
    const newBlobClient = containerClient.getBlobClient(newName);

    const poller = await newBlobClient.beginCopyFromURL(blobClient.url);
    await poller.pollUntilDone();

    /* test code to ensure that blob and its properties/metadata are copied over
    const prop1 = await blobClient.getProperties();
    const prop2 = await newBlobClient.getProperties();
  
    if (prop1.contentLength !== prop2.contentLength) {
      throw new Error("Expecting same size between copy source and destination");
    }
  
    if (prop1.contentEncoding !== prop2.contentEncoding) {
      throw new Error("Expecting same content encoding between copy source and destination");
    }
  
    if (prop1.metadata.keya !== prop2.metadata.keya) {
      throw new Error("Expecting same metadata between copy source and destination");
    }
    */

    await blobClient.delete();

    return newBlobClient;
  }
}

const instance = new RenameAzureFileService();
export default instance;
