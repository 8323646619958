import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./LogoutPage.module.css";
import LogoutForm from "../../form/logout/LogoutForm";
import logoutService from "../../../services/autentication/logout.service";
import { UserContext } from "../../../context/UserContext";

export const LogoutPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  async function doLogout() {
    logoutService.logout();
    if (userContext) {
      userContext.setUser(null);
    }
    navigate("/login");
  }

  return (
    <section className={classes.section}>
      <LogoutForm doLogout={doLogout} />
    </section>
  );
};

export default LogoutPage;
