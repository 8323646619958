import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { Button } from "../../../ui/button/Button";
import { OrganizationPrincipalHeaderItem } from "../../../ui/organization/principal/OrganizationPrincipalHeaderItem";
import { OrganizationPrincipalList } from "../../../ui/organization/principal/OrganizationPrincipalList";
import classes from "./HomePrincipalPage.module.css";
import trainingListOrganizationService from "../../../../services/training/training.list.organization.service";
import trainingFileService from "../../../../services/training/training.file.service";
import { ConverTo } from "../../../../types/training.types";
import principalOrganizationGetService from "../../../../services/principal/principal.organization.get.service";

export const HomePrincipalPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [showVRVideo, setShowVRVideo] = useState(false);
  const [showVRApplication, setShowVRAppplication] = useState(false);
  const [organizationName, setOrganizationName] = useState("");

  function addUser() {
    navigate("/user/add");
  }

  useEffect(() => {
    if (userContext && userContext.user) {
      setOrganizationName(userContext.user.organizationname);
      setShowVRVideo(userContext.user.can_access_video === 1 ? true : false);
      setShowVRAppplication(userContext.user.can_access_application === 1 ? true : false);
    }
  }, [userContext]);

  async function downloadApplication() {
    if (userContext && userContext.user) {
      const item = await principalOrganizationGetService.get(userContext.user.organizationid);
      navigate("/azure/storage/principal/download/application", { state: item });
    }
  }

  function downloadTraininglog() {
    if (userContext && userContext.user) {
      trainingListOrganizationService.list(userContext.user.organizationid).then((res) => {
        trainingFileService.load(res, "traininglog");
        trainingFileService.sendAs(ConverTo.CSV); // ConverTo.JSON
      });
    }
  }

  async function editVideoSequence() {
    if (userContext && userContext.user) {
      const item = await principalOrganizationGetService.get(userContext.user.organizationid);
      navigate("/azure/storage/principal/manage/video", { state: item });
    }
  }

  return (
    <div className={classes.container}>
      <h1>{organizationName}</h1>
      <br />
      <Button text="Add a user" onClick={addUser} />
      {showVRApplication && <Button text="Download application" onClick={downloadApplication} />}
      {showVRVideo && <Button text="VRguide video" onClick={editVideoSequence} />}
      <Button text="Download training log" onClick={downloadTraininglog} />
      <br />
      <br />
      <OrganizationPrincipalHeaderItem />
      <OrganizationPrincipalList />
    </div>
  );
};
