import axios from "../../api/axios.private";

class UserDeleteService {
  async delete(id: number, organization_id: string) {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/users/delete";
    const data = {
      id: id,
      organization_id: organization_id,
    };

    await axios
      .post(url, data, config)
      .then((res) => {})
      .catch((err) => console.log(err));
    //.catch((err) => {});
  }
}

const instance = new UserDeleteService();
export default instance;
