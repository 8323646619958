import { BlobServiceClient } from "@azure/storage-blob";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";
import azuresasGenerateService from "./azuresas.generate.service";

class AzureSASDeleteBlobService {
  async delete(identifier: string, storageaccount: string, containername: string, blobname: string) {
    const sasCredentials = await azuresasGenerateService.generate(identifier, containername, AZURESAS_TOKEN_EXPIRATION.VERY_LONG);
    const blobService = new BlobServiceClient(`https://${storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containername);

    await containerClient.deleteBlob(blobname);

    //const data = await containerClient.deleteBlob(blobname);
    //console.log(data);
  }
}

const instance = new AzureSASDeleteBlobService();
export default instance;
