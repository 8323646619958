import { useEffect, useState } from "react";
import { OrganizationHeaderItem } from "./OrganizationHeaderItem";
import { OrganizationItem } from "./OrganizationItem";
import classes from "./OrganizationList.module.css";
import organizationListService from "../../../services/organization/organization.list.service";
import { BackendOrganizationItem, BackendOrganizationItemProps, OrganizationListProps } from "../../../types/organization.types";
import { BackendOrganizationStatisticsStats } from "../../../types/organization.statistics.stats.types";
import organizationStatisticsStatsAllService from "../../../services/organization/organization.statistic.stats";

export const OrganizationList = ({ onOrganizationListClicked }: OrganizationListProps) => {
  const [organizationList, setOrganizationList] = useState<BackendOrganizationItem[]>([]);
  const [organizationStatsList, setOrganizationStatsList] = useState<BackendOrganizationStatisticsStats[]>([]);

  useEffect(() => {
    organizationListService.all().then((res) => {
      setOrganizationList(res);
    });
    organizationStatisticsStatsAllService.all().then((res) => {
      setOrganizationStatsList(res);
      //console.log(res);
    });
  }, []);

  function onClick(place: string, item: BackendOrganizationItem) {
    onOrganizationListClicked(place, item);
  }

  function getStat(item: BackendOrganizationItem): BackendOrganizationStatisticsStats {
    const stat = organizationStatsList.filter((filterItem) => {
      return filterItem.organization_id === item.organization_id;
    });

    if (stat.length === 0) {
      const item: BackendOrganizationStatisticsStats = {
        organization_id: "",
        actives: 0,
        admins: 0,
        users: 0,
        auditlogs: 0,
      };
      return item;
    } else {
      return stat[0];
    }
  }

  function getActives(item: BackendOrganizationItem): number {
    return getStat(item).actives;
  }
  function getAdmins(item: BackendOrganizationItem): number {
    return getStat(item).admins;
  }
  function getUsers(item: BackendOrganizationItem): number {
    return getStat(item).users;
  }
  function getAuditlogs(item: BackendOrganizationItem): number {
    return getStat(item).auditlogs;
  }

  const list = organizationList.map(function (mapItem: BackendOrganizationItem, id: number) {
    const dataitem: BackendOrganizationItem = {
      active: mapItem.active,
      appid: mapItem.appid,
      id: mapItem.id,
      identifier: mapItem.identifier,
      organization_id: mapItem.organization_id,
      name: mapItem.name,
      container_app: mapItem.container_app,
      container_video: mapItem.container_video,
      storage_name: mapItem.storage_name,
      access_key: mapItem.access_key,
    };
    const props: BackendOrganizationItemProps = {
      actives: getActives(mapItem),
      admins: getAdmins(mapItem),
      users: getUsers(mapItem),
      auditlogs: getAuditlogs(mapItem),
      item: dataitem,
      onClick: onClick,
    };
    return <OrganizationItem key={id} item={props.item} actives={props.actives} admins={props.admins} users={props.users} auditlogs={props.auditlogs} onClick={props.onClick} />;
  });

  return (
    <div className={classes.container}>
      <h1>Organizations</h1>
      <OrganizationHeaderItem />
      {list}
    </div>
  );
};
