import { useContext, useEffect, useRef, useState } from "react";
//import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { BackendOrganizationItem } from "../../../../../types/organization.types";
import { Button } from "../../../button/Button";
import { CheckBox } from "../../../checkbox/CheckBox";
import classes from "./UserAddPrincipalPage.module.css";
import principalUserAddService from "../../../../../services/principal/principal.user.add.service";

export const UserAddPrincipalPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);

  const [locationState, setLocationState] = useState<BackendOrganizationItem>();
  const [username, setUsername] = useState("");
  const [active, setActive] = useState(false);
  const [canExpire, setCanExpire] = useState(false);
  const [error, setError] = useState("");

  //const [selectedDay, setSelectedDay] = useState<DayValue>();
  const [selectedDay, setSelectedDay] = useState<string>(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().substring(0, 10));

  useEffect(() => {
    setLocationState(location.state);
    // setSelectedDay({
    //   day: new Date().getDate(),
    //   month: new Date().getMonth() + 1,
    //   year: new Date().getFullYear(),
    // });
  }, []);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const selectedDayInputRef = useRef<HTMLInputElement>(null);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "admin") {
      return <div>403</div>;
    }
  }

  async function save(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (selectedDay && userContext && userContext.user && userContext.user.organizationid) {
      //console.log(selectedDay);
      //const expiresOn = new Date(selectedDay.year ?? 0, selectedDay.month - 1 ?? 0, selectedDay.day + 1 ?? 0).toISOString().split("T")[0];
      //console.log(expiresOn);

      if (selectedDay.split("-").length !== 3) {
        return;
      }

      const yyyy = parseInt(selectedDay.split("-")[0]);
      const mm = parseInt(selectedDay.split("-")[1]);
      const dd = parseInt(selectedDay.split("-")[2]);
      const expiresOn = new Date(yyyy ?? 0, mm - 1 ?? 0, dd + 1 ?? 0).toISOString().split("T")[0];

      const statusCode = await principalUserAddService.add(username, active ? "1" : "0", canExpire ? "1" : "0", expiresOn, userContext.user.organizationid);

      if (statusCode === 200) {
        navigate(-1);
      } else {
        setError("an error occured");
      }
    }
  }

  function activeChanged(active: boolean) {
    setActive(active);
  }

  function canExpireChanged(active: boolean) {
    setCanExpire(active);
  }

  return (
    <div className={classes.container}>
      <h1>New user</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <div className={classes.control}>
        <label htmlFor="username">Username</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="username"
          autoComplete="off"
          ref={usernameInputRef}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </div>
      <span className={classes.error}>{error}</span>
      <br />
      <CheckBox initialState={active} label="Active" onStateChange={activeChanged} />
      <CheckBox initialState={canExpire} label="Can expire" onStateChange={canExpireChanged} />
      <br />
      <div>
        {/* <p>Expires on</p>
        <DatePicker value={selectedDay} onChange={setSelectedDay} inputPlaceholder="Select a day" shouldHighlightWeekends /> */}
        <div className={classes.control}>
          <label htmlFor="selectedDay">Expire On (YYYY-MM-DD eg 2025-12-31)</label>
          <input
            data-lpignore="true"
            type="text"
            required
            id="selectedDay"
            autoComplete="off"
            ref={selectedDayInputRef}
            value={selectedDay}
            onChange={(e) => {
              setSelectedDay(e.target.value);
            }}
          />
        </div>
      </div>
      <br />
      <Button text="Save" onClick={save} />
    </div>
  );
};
