import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../ui/button/Button";
import classes from "./OrganizationEditPage.module.css";
import organizationEditService from "../../../../services/organization/organization.edit.service";
import organizationDeleteService from "../../../../services/organization/organization.delete.service";
import { CheckBox } from "../../../ui/checkbox/CheckBox";
import { UserContext } from "../../../../context/UserContext";

export const OrganizationEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [name, setName] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [id, setId] = useState(-1);
  const [active, setActive] = useState(false);
  const [containerapp, setContainerApp] = useState("app"); // default values and must match in storrage container
  const [containervideo, setContainerVideo] = useState("video"); // default values and must match in storrage container
  const [storagename, setStorageName] = useState("");
  const [accesskey, setAccessKey] = useState("");

  useEffect(() => {
    setName(location.state.name);
    setIdentifier(location.state.identifier);
    setId(location.state.id);
    setActive(location.state.active === 1 ? true : false);
    setContainerApp(location.state.container_app);
    setContainerVideo(location.state.container_video);
    setStorageName(location.state.storage_name);
    setAccessKey(location.state.access_key);
  }, [location.state.name, location.state.identifier, location.state]);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const identifierInputRef = useRef<HTMLInputElement>(null);
  const containerAppInputRef = useRef<HTMLInputElement>(null);
  const containerVideoInputRef = useRef<HTMLInputElement>(null);
  const storageNameInputRef = useRef<HTMLInputElement>(null);
  const accessKeyInputRef = useRef<HTMLInputElement>(null);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "sysadmin") {
      return <div>403</div>;
    }
  }

  async function save(event: { preventDefault: () => void }) {
    event.preventDefault();
    await organizationEditService.edit(id, name, identifier, active ? 1 : 0, containerapp, containervideo, storagename, accesskey);
    navigate(-1);
  }

  async function remove(event: { preventDefault: () => void }) {
    event.preventDefault();
    await organizationDeleteService.delete(id);
    navigate(-1);
  }

  function isActive(active: boolean) {
    setActive(active);
  }

  return (
    <div className={classes.container}>
      <h1>Edit Organization</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <div className={classes.control}>
        <label htmlFor="organizationname">Organization name</label>
        <input
          type="text"
          required
          id="organizationname"
          autoComplete="off"
          ref={nameInputRef}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="organizationidentifier">Organization identifier</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="organizationidentifier"
          autoComplete="off"
          ref={identifierInputRef}
          value={identifier}
          onChange={(e) => {
            setIdentifier(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="storagename">Storage account name</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="storagename"
          autoComplete="off"
          ref={storageNameInputRef}
          value={storagename}
          onChange={(e) => {
            setStorageName(e.target.value);
          }}
        />
      </div>
      <br />
      <div className={classes.control}>
        <label htmlFor="accesskey">Access key</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="accesskey"
          autoComplete="off"
          ref={accessKeyInputRef}
          value={accesskey}
          onChange={(e) => {
            setAccessKey(e.target.value);
          }}
        />
      </div>
      <CheckBox initialState={active} label="Active" onStateChange={isActive} />
      <br />
      <Button text="Save" onClick={save} />
      <Button text="Delete" onClick={remove} />
    </div>
  );
};
