import classes from "./ContainerVideoOrganizeList.module.css";
import azuresasAccountService from "../../../services/azuresas/azuresas.account.service";
import azuresasManifestService from "../../../services/azuresas/azuresas.manifest.service";
import azuresasListblobService from "../../../services/azuresas/azuresas.listblob.service";
import { useContext, useEffect, useState } from "react";
import { ContainerVideoHeaderOrganizeItem } from "./ContainerVideoHeaderOrganizeItem";
import { ContainerVideoOrganizeItem } from "./ContainerVideoOrganizeItem";
import { ContainerVideoOrganizeItemProps, ContainerVideoOrganizeListProps } from "../../../types/filemanager.types";
import { AzureFileBlobItem } from "../../../types/azuresas.types";
import { UploadVideo } from "../upload/UploadVideo";
import { AppStateContext } from "../../../context/AppStateContext";
import { Button } from "../button/Button";
import { UserContext } from "../../../context/UserContext";
import sharedUtilExtractFilestructure from "../../../services/shared/shared.util.extract.filestructure";
import Modal from "../modal/Modal";
import { CircularProgressbar } from "react-circular-progressbar";
import useModal from "../../form/hooks/useModal";

export const ContainerVideoOrganizeList = ({ update, identifier, container }: ContainerVideoOrganizeListProps) => {
  const appState = useContext(AppStateContext);
  const userContext = useContext(UserContext);
  const [containerName, setContainerName] = useState("");
  const [viewData, setViewData] = useState<string[]>([]);
  const [reload, setReload] = useState(false);
  const [fileList, setFileList] = useState<AzureFileBlobItem[]>([]);
  const [storrageAccountName, setStorrageAccountName] = useState("");
  const [modifiedManifest, setModifiedManifest] = useState("");
  const [showAdmin, setShowAdmin] = useState(false);
  const [uploadProgressInPercentage, setUploadProgressInPercentage] = useState(0);
  const { isOpen, toggle } = useModal();
  const [canClose, setCanClose] = useState(false);
  const [actionText, setActionText] = useState("");
  const [isEditingManifest, setIsEditingManifest] = useState(false);
  const [offlineManifestList, setOfflineManifestList] = useState<string[]>([]);
  const [updateManifestView, setUpdateManifestView] = useState(false);

  useEffect(() => {
    if (userContext && userContext.user && userContext.user.type) {
      setShowAdmin(userContext.user.type === "sysadmin" ? true : false);
    }
    setContainerName(container);
    if (!isEditingManifest) {
      load();
    }
  }, [container, containerName, reload, storrageAccountName, updateManifestView]);

  function reloadPage() {
    setReload(!reload);
  }

  function updateManifestViewOnChange() {
    setUpdateManifestView(!updateManifestView);
  }

  function togglePopup() {
    // cancel the current oppertaion here!
    if (canClose) {
      window.location.reload();
      reloadPage();
      toggle();
    }
  }

  async function load() {
    if (appState) {
      appState.setState({ spinner: true });
    }
    const accountData = await azuresasAccountService.get(identifier);
    setStorrageAccountName(accountData.storageaccount);

    if (containerName.length !== 0) {
      const manifest = await azuresasManifestService.get(identifier, accountData.storageaccount, containerName);
      setModifiedManifest("");
      manifest.forEach((line) => {
        setModifiedManifest((prev) => (prev += line + "\n"));
      });

      try {
        const accountData = await azuresasAccountService.get(identifier);
        const list = await azuresasListblobService.list(identifier, accountData.storageaccount, containerName, true);
        setFileList(list);

        // this code will add the thumbnail into the re-order manifest view
        // list.forEach((file) => {
        //   const ext = file.name.split(".")[1];
        //   if (ext === "png") {
        //     const entry = "IMG:https://" + accountData.storageaccount + ".blob.core.windows.net/" + containerName + "/" + file.name;
        //     manifest.push(entry);
        //   }
        // });

        setOfflineManifestList(manifest);
        setViewData(manifest);
      } catch (err) {
        console.log(err);
      }
    }
    if (appState) {
      appState.setState({ spinner: false });
    }
  }

  async function handleOrganize(item: string, direction: string) {
    setIsEditingManifest(true);
    let manifest: string[] = offlineManifestList;

    const from = manifest.findIndex((element) => {
      return element === item;
    });

    let to = 0;

    if (direction === "up") {
      to = from - 1;
    }
    if (direction === "down") {
      to = from + 1;
    }

    var f = manifest.splice(from, 1)[0];
    manifest.splice(to, 0, f);

    setViewData(manifest);
    setOfflineManifestList(manifest);
    updateManifestViewOnChange();
  }

  function findAzureFile(file: string): AzureFileBlobItem {
    return fileList.filter((azureFile) => {
      return azureFile.name.includes(file);
    })[0];
  }

  async function setManifestFile() {
    const manifest = modifiedManifest.split("\n");
    await azuresasManifestService.set(identifier, storrageAccountName, containerName, manifest);
    reloadPage();
  }

  const list = viewData.map(function (item: string, id: number) {
    const info = sharedUtilExtractFilestructure.extract(item, "https://" + storrageAccountName + ".blob.core.windows.net");

    let props: ContainerVideoOrganizeItemProps = {
      item: item,
      azurefile: findAzureFile(info.filename),
      displaytext: "[" + info.type + "] " + info.path,
      onClickUp: function (item: string): void {
        handleOrganize(item, "up");
      },
      onClickDown: function (item: string): void {
        handleOrganize(item, "down");
      },
    };
    return <ContainerVideoOrganizeItem key={id} {...props} />;
  });

  async function onUploadStartedAction() {
    setUploadProgressInPercentage(0);
    setCanClose(true);
    toggle();
  }

  async function onUploadProgressAction(percentage: number) {
    //console.log(percentage);
    setUploadProgressInPercentage(percentage);
  }

  function uploadComplete() {
    setActionText("");
    setUploadProgressInPercentage(100);
    reloadPage();
    toggle();
    setActionText("");
  }

  async function completeRearrangeOffline() {
    setIsEditingManifest(false);
    await azuresasManifestService.set(identifier, storrageAccountName, containerName, offlineManifestList);
    reloadPage();
  }

  return (
    <div className={classes.container}>
      <UploadVideo
        identifier={identifier}
        container={containerName}
        reloadParent={function (): void {
          reloadPage();
        }}
        onUploadStarted={function (): void {
          onUploadStartedAction();
        }}
        onUploadProgress={function (percentage: number): void {
          onUploadProgressAction(percentage);
        }}
        onUploadDone={function (): void {
          uploadComplete();
        }}
      />
      <br />
      <br />
      <div>
        <h2>Rearrange content</h2>
        <ContainerVideoHeaderOrganizeItem />
        {list}
        {isEditingManifest && (
          <div>
            <Button
              text="Done with rearrange"
              onClick={() => {
                completeRearrangeOffline();
              }}
            />
          </div>
        )}
      </div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <div>
          <Button text="X" onClick={togglePopup} />
        </div>
        <div>
          <br />
          <p>Action in pogress...</p>
          <br />
          <p>Please wait until it is a 100% - then you can close this by pressing the X</p>
          <div className={classes.poscircle}>
            {uploadProgressInPercentage !== 0 && (
              <div className={classes.progresscircle}>
                <CircularProgressbar value={uploadProgressInPercentage} text={`${uploadProgressInPercentage}%`} />
              </div>
            )}
          </div>
          <br />
          <p>{actionText}</p>
        </div>
      </Modal>
      {showAdmin && (
        <div>
          <div>
            <h2>Superadmin menu:</h2>
            Set manifest file manually - this is only shown to sysadmins so that is anything happens we can edit this file raw
            <div>
              <textarea cols={100} rows={20} value={modifiedManifest} onChange={(e) => setModifiedManifest(e.target.value)}></textarea>
              <br />
              <Button text="Set manifest file" onClick={setManifestFile} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
