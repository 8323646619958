import { ContainerVideoPrincipalItemProps } from "../../../../types/principal.types";
import classes from "./ContainerApplicationPrincipalItem.module.css";

export const ContainerApplicationPrincipalItem = ({ onClickActionDownload, item }: ContainerVideoPrincipalItemProps) => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.file}>{item.name}</div>
      <div
        className={classes.action}
        onClick={() => {
          onClickActionDownload(item);
        }}
      >
        Download
      </div>
    </div>
  );
};
