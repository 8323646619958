import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import azuresasAccountService from "../azuresas/azuresas.account.service";
import azuresasGenerateService from "../azuresas/azuresas.generate.service";
import { AZURESAS_TOKEN_EXPIRATION } from "../../types/common.types";

class UploadAzureFileService {
  async execute(
    onUploadProgress: (file: File, bytes: number) => void,
    onCompleted: (azureUrl: string) => void,
    onUploadStarted: () => void,
    onError: () => void,
    identifier: string,
    containerName: string,
    file: File,
    folderpath: string
  ): Promise<string> {
    const accountData = await azuresasAccountService.get(identifier);
    const sasCredentials = await azuresasGenerateService.generate(identifier, containerName, AZURESAS_TOKEN_EXPIRATION.LONG);
    const blobService = new BlobServiceClient(`https://${accountData.storageaccount}.blob.core.windows.net/?${sasCredentials.sas}`);
    const containerClient = blobService.getContainerClient(containerName);

    let absFile = "";
    if (folderpath.length === 0) {
      absFile = file.name; // put it in the root
    } else {
      absFile = folderpath + "/" + file.name;
    }

    const blockBlobClient = await containerClient.getBlockBlobClient(absFile);
    const options: BlockBlobParallelUploadOptions = {
      onProgress: (progress) => {
        onUploadProgress(file, progress.loadedBytes);
      },
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    };

    try {
      // upload file to azure
      onUploadStarted();
      const uploadBlobResponse = await blockBlobClient.upload(file, file.size, options);
      console.log(`Upload block blob ${file.name} successfully`, uploadBlobResponse.requestId);
    } catch (error) {
      console.log(error);
      onError();
    }

    const azureUrl = `https://${accountData.storageaccount}.blob.core.windows.net/${containerName}/${absFile}`;

    onCompleted(azureUrl);

    return azureUrl;
  }
}

const instance = new UploadAzureFileService();
export default instance;
