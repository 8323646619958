import { useEffect, useState } from "react";
import classes from "./CheckBox.module.css";

interface CheckBoxProps {
  label: string;
  initialState: boolean;
  onStateChange(checked: boolean): void;
}

export const CheckBox = ({ onStateChange, initialState, label }: CheckBoxProps) => {
  const [isChecked, setIsChecked] = useState(initialState);

  useEffect(() => {
    setIsChecked(initialState);
  }, [initialState, isChecked]);

  return (
    <div className={classes.checkboxwrapper}>
      <label>
        <input
          className={isChecked ? classes.checked : ""}
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked((prev) => !prev);
            onStateChange(!isChecked);
          }}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};
