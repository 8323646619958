import { ThumbnailControlProps } from "../../../types/thumbnailcontrol.types";
import { FileSelect } from "../../form/fileselect/FileSelect";
import { BoxAction } from "../boxaction/BoxAction";
import classes from "./ThumbnailControl.module.css";

export const ThumbnailControl = ({ onClickDelete, onClickUpload, text, url, sasurl: thumbnaillink, candelete, canupload }: ThumbnailControlProps) => {
  function localOnClickDelete() {
    onClickDelete(text);
  }
  function localOnClickUpload(items: FileList) {
    onClickUpload(items);
  }
  return (
    <div className={classes.edgebox}>
      <h2>Folder thumbnail</h2>
      <img className={classes.image} src={thumbnaillink} alt="new" />
      <div className={classes.center}>
        <BoxAction
          id={"delete"}
          text={"Delete"}
          onClick={function (id: string, text: string): void {
            localOnClickDelete();
          }}
          disabled={!candelete}
        />
        <FileSelect
          acceptFileExtensionList={["png", "jpeg", "jpg"]}
          onFilesSelected={function (items: FileList): void {
            localOnClickUpload(items);
          }}
          text={"Upload"}
          disabled={!canupload}
        />
      </div>
      <p>You can use png, jpeg or jpg images</p>
    </div>
  );
};
