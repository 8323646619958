import classes from "./ContainerApplicationList.module.css";
import azuresasAccountService from "../../../services/azuresas/azuresas.account.service";
import azuresasListblobService from "../../../services/azuresas/azuresas.listblob.service";
import azuresasDeleteBlobService from "../../../services/azuresas/azuresas.delete.blob.service";
import azuresasDownloadService from "../../../services/azuresas/azuresas.download.service";
import { ContainerVideoItemProps, ContainerVideoListProps } from "../../../types/filemanager.types";
import { useContext, useEffect, useState } from "react";
import { AzureFileBlobItem } from "../../../types/azuresas.types";
import { ContainerApplicationItem } from "./ContainerApplicationItem";
import { ContainerApplicationHeaderItem } from "./ContainerApplicationHeaderItem";
import { UploadApplication } from "../upload/UploadApplication";
import Modal from "../modal/Modal";
import useModal from "../../form/hooks/useModal";
import { Button } from "../button/Button";
import { AppStateContext } from "../../../context/AppStateContext";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const ContainerApplicationList = ({ onUploadComplete, identifier, container }: ContainerVideoListProps) => {
  const [containerName, setContainerName] = useState("");
  const [fileList, setFileList] = useState<AzureFileBlobItem[]>([]);
  const [reload, setReload] = useState(false);
  const { isOpen, toggle } = useModal();
  const [canClose, setCanClose] = useState(false);
  const [actionText, setActionText] = useState("");
  const [uploadProgressInPercentage, setUploadProgressInPercentage] = useState(0);
  const appState = useContext(AppStateContext);

  useEffect(() => {
    setContainerName(container);
    getFileList();
  }, [container, containerName, reload]);

  function reloadPage() {
    setReload(!reload);
  }

  function togglePopup() {
    // cancel the current oppertaion here!
    if (canClose) {
      window.location.reload();
      reloadPage();
      toggle();
    }
  }

  function uploadComplete() {
    setActionText("");
    setUploadProgressInPercentage(100);
    reloadPage();
    toggle();
    onUploadComplete();
    setActionText("");
    window.location.reload();
  }

  async function getFileList() {
    if (appState) {
      appState.setState({ spinner: true });
    }
    const accountData = await azuresasAccountService.get(identifier);
    const list = await azuresasListblobService.list(identifier, accountData.storageaccount, containerName, true);
    setFileList(list);
    if (appState) {
      appState.setState({ spinner: false });
    }
  }

  async function onClickActionDelete(item: AzureFileBlobItem) {
    setActionText("");
    setUploadProgressInPercentage(0);
    const accountData = await azuresasAccountService.get(identifier);
    await azuresasDeleteBlobService.delete(identifier, accountData.storageaccount, containerName, item.name);
    setCanClose(true);
    setActionText("DONE");
    window.location.reload();
  }

  async function onClickActionDownload(item: AzureFileBlobItem) {
    setActionText("");
    setUploadProgressInPercentage(0);
    const accountData = await azuresasAccountService.get(identifier);
    await azuresasDownloadService.downloadArtifact(identifier, accountData.storageaccount, containerName, item.name);
    setActionText("DONE");
    setCanClose(true);
    window.location.reload();
  }

  async function onUploadStartedAction() {
    setUploadProgressInPercentage(0);
    setCanClose(true);
    toggle();
  }

  async function onUploadProgressAction(percentage: number) {
    setUploadProgressInPercentage(percentage);
  }

  const list = fileList.map(function (mapItem: AzureFileBlobItem, id: number) {
    let props: ContainerVideoItemProps = {
      item: mapItem,
      onClickActionDelete: (item: AzureFileBlobItem) => {
        setActionText("DELETING");
        setCanClose(false);
        toggle();
        onClickActionDelete(item);
      },
      onClickActionDownload: (item: AzureFileBlobItem) => {
        //setActionText("DOWNLOADING");
        //setCanClose(false);
        //toggle();
        onClickActionDownload(item);
      },
    };
    return <ContainerApplicationItem key={id} {...props} />;
  });

  return (
    <div className={classes.container}>
      <div>
        <ContainerApplicationHeaderItem />
        {list}
      </div>
      <UploadApplication
        identifier={identifier}
        container={containerName}
        reloadParent={function (): void {}}
        onUploadDone={uploadComplete}
        onUploadStarted={onUploadStartedAction}
        onUploadProgress={onUploadProgressAction}
      />
      <Modal isOpen={isOpen} toggle={toggle}>
        <div>
          <Button text="X" onClick={togglePopup} />
        </div>
        <div>
          <br />
          <p>Action in pogress...</p>
          <br />
          <p>Wait until it is done - or cancel opperation on the X in the left top corner</p>
          {uploadProgressInPercentage !== 0 && (
            <div className={classes.progresscircle}>
              <CircularProgressbar value={uploadProgressInPercentage} text={`${uploadProgressInPercentage}%`} />
            </div>
          )}
          <br />
          <p>{actionText}</p>
        </div>
      </Modal>
    </div>
  );
};
