import axios from "../../api/axios.private";
import secureLocalStorage from "react-secure-storage";

class LogoutService {
  async logout(): Promise<boolean> {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };
    const url = "/ui/autentication/logout";

    await axios
      .post(url, config)
      .then((res) => {
        secureLocalStorage.clear();
      })
      .catch((err) => console.log(err));

    return true;
  }
}

const instance = new LogoutService();
export default instance;
