import azuresasAccountService from "../azuresas/azuresas.account.service";
import azuresasDeleteBlobService from "../azuresas/azuresas.delete.blob.service";

class DeleteAzureFileService {
  async execute(identifier: string, containerName: string, blobname: string) {
    const accountData = await azuresasAccountService.get(identifier);
    await azuresasDeleteBlobService.delete(identifier, accountData.storageaccount, containerName, blobname);
  }
}

const instance = new DeleteAzureFileService();
export default instance;
