import { UserItemProps } from "../../../../types/users.types";
import classes from "./AdminItem.module.css";

export const AdminItem = ({ onClick, item }: UserItemProps) => {
  function resolveActive() {
    return item.active === 1 ? "Yes" : "No";
  }
  function resolveHasPassword() {
    return item.has_temp_password === 1 ? "Yes" : "No";
  }
  function resolveCanExpire() {
    return item.can_expire === 0 ? "No" : "Yes";
  }
  function resolveCanAccessVideo() {
    return item.can_access_video === 0 ? "No" : "Yes";
  }
  function resolveCanAccessApplication() {
    return item.can_access_application === 0 ? "No" : "Yes";
  }
  function onItemClick() {
    onClick(item);
  }
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username} key={item.id}>
        <div>{item.username}</div>
      </div>
      <div className={classes.canaccessvideo}>{resolveCanAccessVideo()}</div>
      <div className={classes.canaccessapplication}>{resolveCanAccessApplication()}</div>
      <div className={classes.haspassword}>{resolveHasPassword()}</div>
      <div className={classes.active}>{resolveActive()}</div>
      <div className={classes.canexpire}>{resolveCanExpire()}</div>
      <div className={classes.expireson}>{item.expires_on}</div>
      <div className={classes.edit} onClick={onItemClick}>
        Edit
      </div>
    </div>
  );
};
