import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { BackendOrganizationItem } from "../../../../../types/organization.types";
import { Button } from "../../../../ui/button/Button";
import { ContainerVideoOrganizeList } from "../../../../ui/containervideo/ContainerVideoOrganizeList";
import classes from "./AzureStoragePrincipalVideoPage.module.css";

export const AzureStoragePrincipalVideoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [update, setUpdate] = useState(false);
  const [locationState, setLocationState] = useState<BackendOrganizationItem>();

  useEffect(() => {
    //console.log("useEffect");
    //console.log(location.state);
    setLocationState(location.state);
  }, [location.state, update]);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "admin") {
      return <div>403</div>;
    }
  }

  function uploadComplete() {
    setUpdate(!update);
  }

  return (
    <div className={classes.container}>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <h1>Manage folder content</h1>
      <ContainerVideoOrganizeList update={update} identifier={location.state.identifier} container={location.state.container_video} />
    </div>
  );
};
