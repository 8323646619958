import { FolderBoxProps } from "../../../types/folderbox.types";
import classes from "./FolderBox.module.css";

export const FolderBox = ({ onClick, onClickSecondary, id, text, secondarytext }: FolderBoxProps) => {
  function localOnClick() {
    onClick(id, text);
  }
  function localOnClickSecondary() {
    onClickSecondary(id, text);
  }
  return (
    <div className={classes.row}>
      <div title="file" className={[classes.column1].join(" ")} onClick={localOnClick}>
        {text}
      </div>
      <div title="action" className={[classes.column2].join(" ")} onClick={localOnClickSecondary}>
        {secondarytext}
      </div>
    </div>
  );
};
