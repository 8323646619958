import { useContext, useRef, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import { Button } from "../../../ui/button/Button";
import classes from "./HomePrincipalSetNewPassword.module.css";
import principalSetPasswordService from "../../../../services/principal/principal.set.password.service";
import { useNavigate } from "react-router-dom";

export const HomePrincipalSetNewPassword = () => {
  const navigate = useNavigate();
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState("");

  const userContext = useContext(UserContext);
  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
  }

  async function onClickSetPassword() {
    if (userContext && userContext.user) {
      const httpCode = await principalSetPasswordService.set(userContext.user.id, userContext.user.name, password);
      //console.log(httpCode);
      if (httpCode === 200) {
        navigate("/");
      }
    }
  }

  function setupTwoFAClicked() {
    navigate("/2fa-setup");
  }

  return (
    <div className={classes.container}>
      <div>
        <h1>Welcome new admin!</h1>
        <br />
        <p>To protect your account you need to set a better and stronger password.</p>
        <br />
        <h3>2FA (Two factor autentication)</h3>
        <p>To setup 2FA you need a mobile phone with a autenticator app like Google Autenticator App</p>
        <br />
        <Button text="Setup 2FA now" onClick={setupTwoFAClicked} />
        <br />
        <br />
        <div className={classes.control}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            required
            id="password"
            autoComplete="off"
            ref={passwordInputRef}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <br />
        <Button text="Set new password" onClick={onClickSetPassword} />
        <br />
        <br />
        <p>Once you have set the new password you will be automatically logged out.</p>
        <p>Then you need to login with your new credentials.</p>
      </div>
    </div>
  );
};
